import { buildWatchButton } from "./buildWatchButton.js";

export const buildMyShowsCarouselItem = (data) => {
    const { combinedTitleID, title, slug, slugTypeFolder, userNextEpisode,releasedEpisodes, unwatchedEpisodes, userLastWatched, type } = data;
    const { episodeID, episode: episodeNumber, season: seasonNumber, consolidatedProviders, thumbnail, airDate: nextEpisodeAirdate, title: episodeTitle } = userNextEpisode;
    const titleAlphaOnly = title.replace(/[^a-zA-Z0-9]/g, '');
    const titleEncoded = encodeURIComponent(title.title);
    const watchButtonTitle = {
        title: title,
        type: type,
        consolidatedProviders: consolidatedProviders
    };

    const watchButton = buildWatchButton(watchButtonTitle);
    const episodeNumberText = `Season ${seasonNumber} Episode ${episodeNumber}`;

    return `<div class="upNextEpisode" data-combinedtitleid="${combinedTitleID}" data-title="${titleAlphaOnly}" data-lastwatched="${userLastWatched}" data-releasedepisodes="${releasedEpisodes}" data-unwatchedepisodes="${unwatchedEpisodes}" data-airdatetimestamp="${nextEpisodeAirdate}">
	<div class="episodeThumbnailContainer me-1">
		<div>
			<a href="/${slugTypeFolder}/${slug}/episode-guide/${episodeID}/" class="tpl">
				<img src="${thumbnail}" class="episodeThumbnail" style="cursor: grab;" alt="${titleAlphaOnly}">
			</a>
		</div>
		<div class="upNextThumbnailTitle">${title}</div>
		<div class="upNextThumbnailEpisodeTitle">${episodeTitle}</div>
		<div class="upNextThumbnailEpisodeSubtitle">${episodeNumberText}</div>
		<div class="posterControls myShowsOptionsToggle" data-combinedtitleid="${combinedTitleID}" data-slug="${slug}" data-slugtypefolder="${slugTypeFolder}" data-episodeid="${episodeID}" data-episodenumber="${episodeNumber}" data-seasonnumber="${seasonNumber}" data-title="${titleEncoded}">
			<svg width="25px" height="25px" viewbox="0 0 16 16" class="bi bi-three-dots-vertical white" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
				<path fill-rule="evenodd" d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
			</svg>
		</div>
	</div>
	<div class="d-inline-flex flex-nowrap align-items-center align-content-center">
		<div class="textRegular lightGrey me-2 ">
			<svg width="1em" height="1em" viewbox="0 0 16 16" class="bi bi-check2-circle me-1 markAsSeen" fill="currentColor" xmlns="http://www.w3.org/2000/svg" data-combinedtitleid="${combinedTitleID}" data-episodeid="${episodeID}" data-episodenumber="${episodeNumber}" data-seasonnumber="${seasonNumber}">
				<path class="markAsSeen" fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z" data-combinedtitleid="${combinedTitleID}" data-episodeid="${episodeID}" data-episodenumber="${episodeNumber}" data-seasonnumber="${seasonNumber}"/>
				<path class="markAsSeen" fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z" data-combinedtitleid="${combinedTitleID}" data-episodeid="${episodeID}" data-episodenumber="${episodeNumber}" data-seasonnumber="${seasonNumber}"/>
			</svg>
			<a href="#" class="markAsSeen lightGrey" data-combinedtitleid="${combinedTitleID}" data-episodeid="${episodeID}" data-episodenumber="${episodeNumber}" data-seasonnumber="${seasonNumber}">Mark as seen</a>
		</div> ${watchButton}
	</div>
</div>`;
}