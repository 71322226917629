/*
 * * This class is used by My Shows and /movie/ /tv/ to spawn and control a modal popup that allows a user to update their progress for a specific show
 */
// eslint-disable-next-line no-unused-vars
/* global combinedTitleID, scrollToCombinedTitleID, slug, slugTypeFolder */
import { GATEWAY_DOMAIN } from '../constants.js';
const helperFunctions = require('../helperFunctions.js');
import { buildMyShowsCarouselItem } from '../helpers/buildMyShowsCarouselItem.js';
import { buildWatchButton } from '../helpers/buildWatchButton.js';
export class UpdateProgressModal {
    constructor(mode, combinedTitleID, title, myShows) {
        this.mode = mode;
        this.combinedTitleID = combinedTitleID;
        this.title = title;
        this.seasonNumber = 1;
        this.eg = {};
        this.timeout = null;
        this.setupEventListeners();
        this.updateProgressModal = document.getElementById('updateShowProgressModal');
        this.addShowsModal = document.getElementById('addShowsModal');
        this.myShows = myShows;
    }

    updateValues(mode, combinedTitleID, title) {
        this.mode = mode;
        this.combinedTitleID = combinedTitleID;
        this.title = title;
    }

    setMyShows(myShows) {
        this.myShows = myShows;
    }

    // When a season button is selected, change the selected season and set the range slider max to that seasons's episodes
    changeSelectedSeason(episodeNumber) {
        episodeNumber = episodeNumber ? episodeNumber : 1;
        var seasonButtons = document.querySelectorAll('.seasonButton');
        var foundSeason = false;
        var that = this;
        [].forEach.call(seasonButtons, (seasonButton) => {
            // Find the selected button
            if (seasonButton.classList.contains('btn-primary')) {
                that.seasonNumber = seasonButton.dataset.seasonnumber;
                foundSeason = true;
            }
            if (!foundSeason) {
                that.seasonNumber = 1;
            }
        });
        // See how many episodes we can find with this season number
        var episodesFiltered = that.eg.episodes.filter((episode) => {
            return episode.season == that.seasonNumber;
        });
        // Change the slider range max to the number of episodes in this season
        document.getElementById('progressSlider').max = episodesFiltered.length;
        document.getElementById('progressSlider').value = episodeNumber;
        // Wipe out the current thumbnail
        document.getElementById('progressEpisodeThumbnail').src = 'https://cdn.watchmode.com' + '/episode_thumbnails/blank.gif';
        that.changeEpisodeThumbnail(episodeNumber, false);
    }

    setupEventListeners() {
        var that = this;
        // Listen for clicks on the season buttons
        document.addEventListener(
            'click',
            (event) => {
                // Toggle thje highlighted state of the button pressed, de-select all others
                if (event.target.matches('.seasonButton')) {
                    var seasonButtons = document.querySelectorAll('.seasonButton');
                    [].forEach.call(seasonButtons, (seasonButton) => {
                        if (seasonButton.id != event.target.id) {
                            seasonButton.classList.remove('btn-primary');
                            seasonButton.classList.add('btn-secondary');
                        }
                    });
                    if (!event.target.classList.contains('btn-primary')) {
                        event.target.classList.add('btn-primary');
                        event.target.classList.remove('btn-secondary');
                    }
                    that.changeSelectedSeason(1);
                }

                if (event.target.matches('#progressSave') || event.target.matches('#haventStartedYet')) {
                    event.preventDefault();
                    that.handleSave(event.target);
                }
            },
            false
        );
    }

    // Mode 1= Not from addShows modal, 2= From add shows Modal 3 = From content.php
    showUpdateProgressModal() {
        // Get the episode guide from the API
        var egData = new FormData();
        egData.set('appPlatform', 'web');
        egData.set('combinedTitleID', this.combinedTitleID);
        egData.set('includeSources', 0);
        egData.set('includeUnaired', 0);

        // Show the spinner
        var spinner = document.querySelector('.spinner-wrapper');
        spinner.classList.add('show');
        spinner.classList.remove('hide');
        spinner.style.zIndex = '9999';
        var that = this;
        fetch(GATEWAY_DOMAIN + '/fetchEpisodes/', {
            method: 'POST',
            credentials: 'include',
            body: egData
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                that.eg = data;
                // Remove the spinner
                spinner.classList.add('hide');
                spinner.classList.remove('show');
                spinner.style.zIndex = '-1';

                // Find the furthest watched season and episode
                var furthestSeason = 1;
                var furthestEpisode = 1;

                data.episodes.forEach((episode) => {
                    if (episode.userSeen == 1 && (episode.season > furthestSeason || (episode.season == furthestSeason && episode.episode > furthestEpisode))) {
                        furthestEpisode = episode.episode;
                        furthestSeason = episode.season;
                    }
                });

                // Build the season options
                var seasonButtons = '';
                data.seasons.forEach((season) => {
                    if (that.mode == 2) {
                        // Select the poster, but hide the addShows modal while we show a modal asking for their progress in the show
                        this.addShowsModal.classList.add('hide');
                    }
                    this.updateProgressModal.classList.add('show');
                    this.updateProgressModal.classList.add('in');
                    this.updateProgressModal.style.display = 'block';
                    this.updateProgressModal.style.paddingRight = '13px';
                    if (season.name != 'Specials' && season.episodeCount > 0) {
                        var seasonName = season.name.replace('Season ', '');
                        seasonName = seasonName.replace('Series ', '');
                        var buttonClass = season.seasonNumber == furthestSeason ? 'btn-primary' : 'btn-secondary';
                        seasonButtons += '<div class="btn ' + buttonClass + ' seasonButton me-1 mb-2" id="season' + seasonName + '"  data-seasonnumber="' + season.seasonNumber + '">' + seasonName + '</div>';
                    }
                });
                var seasonButtonsDiv = document.getElementById('seasonButtons');
                seasonButtonsDiv.innerHTML = seasonButtons;
                that.changeSelectedSeason(furthestEpisode);
            });
    }

    getEpisodeGuide() {
        return this.eg;
    }

    changeEpisodeThumbnail(episodeNumber, delay) {
        var that = this;
        // Find the episode that matches the slider value and the selected season button
        var episodesFiltered = this.eg.episodes.filter((episode) => {
            return episode.season == that.seasonNumber && episode.episode == episodeNumber;
        });

        var episode = episodesFiltered[0];
        // Change the thumbnail image
        document.getElementById('progressEpisodeTitle').innerHTML = episode.title;
        document.getElementById('progressEpisodeSubitle').innerHTML = 'Season ' + episode.season + ' Episode ' + episode.episode;
        // Set a timeout to only update the thumbnail after a half a second, reset on change (bypass this on season change)
        clearTimeout(this.timeout);
        if (delay) {
            this.timeout = setTimeout(() => {
                document.getElementById('progressEpisodeThumbnail').src = episode.thumbnail;
            }, 500);
        } else {
            document.getElementById('progressEpisodeThumbnail').src = episode.thumbnail;
        }
    }

    closeProgressModal() {
        if (this.mode == 2) {
            this.addShowsModal.classList.remove('hide');
        }
        this.updateProgressModal.classList.remove('show');
        this.updateProgressModal.classList.remove('in');
        this.updateProgressModal.style.display = 'none';
        this.updateProgressModal.style.paddingRight = '0px';
    }

    handleSave(saveButton) {
        saveButton.disabled = true;
        var that = this;
        // get the mode 1= Not from addShows modal 2= From addShows modal
        if (this.mode == 2) {
            // Find and remove the poster
            var posters = document.querySelectorAll('.popularShowsPoster');
            [].forEach.call(posters, (poster) => {
                if (poster.dataset.combinedtitleid == that.combinedTitleID) {
                    poster.remove();
                }
            });
            // Clear any search term
            var searchInput = document.querySelector('#addShowSearch');
            if (searchInput.value != '') {
                searchInput.value = '';
                // Trigger the input event listener so the API refreshes
                var inputEvent = new Event('input');
                searchInput.dispatchEvent(inputEvent);
            }
        }

        var episodeNumber = 0;
        var seasonNumber = 0;
        if (saveButton.matches('#progressSave')) {
            // Get the episode chosen the slider
            var progressSlider = document.getElementById('progressSlider');
            episodeNumber = progressSlider.value;
            var seasonButtons = document.querySelectorAll('.seasonButton');
            var foundSeason = false;
            [].forEach.call(seasonButtons, (seasonButton) => {
                // Find the selected button
                if (seasonButton.classList.contains('btn-primary')) {
                    seasonNumber = seasonButton.dataset.seasonnumber;
                    foundSeason = true;
                }
                if (!foundSeason) {
                    that.seasonNumber = 1;
                }
            });
        }

        // Now find all of the episodes the user has "seen", anything with a lower season/episode number than selected
        var episodesFiltered = this.eg.episodes.filter((episode) => {
            return episode.season != 0 && episode.episode != 0 && (episode.season < seasonNumber || (episode.season == seasonNumber && episode.episode <= episodeNumber));
        });

        // The API wants the episodes in an array, with a pipe delimited string as the value: seasonNumber|episodeNumber, so let's build that
        var episodesSeen = [];
        [].forEach.call(episodesFiltered, (episode) => {
            episodesSeen.push(episode.season + '|' + episode.episode);
        });

        // Send the show progress to the server
        let showProgressData = new FormData();
        showProgressData.set('appPlatform', 'web');
        showProgressData.set('combinedTitleID', this.combinedTitleID);

        episodesSeen.forEach((episode) => {
            showProgressData.append('episodesSeen[]', episode);
        });

        showProgressData.set('method', 'saveShowProgress');

        fetch(GATEWAY_DOMAIN + '/userActions/', {
            method: 'POST',
            credentials: 'include',
            body: showProgressData
        })
            .then((response) => {
                return response.json();
            })
            .then(() => {
                if (that.mode == 1) {
                    // Close the modal
                    that.closeProgressModal();
                }
                this.handleProgressUpdate(that);
                saveButton.disabled = false;
            });

        if (this.mode == 2) {
            // Add the show to the user's myShows on the server, close the modal and show a toast message
            let myShowsData = new FormData();
            myShowsData.set('appPlatform', 'web');
            myShowsData.set('combinedTitleID', this.combinedTitleID);
            myShowsData.set('method', 'myShowsToggle');

            fetch(GATEWAY_DOMAIN + '/userActions/', {
                method: 'POST',
                credentials: 'include',
                body: myShowsData
            })
                .then((response) => {
                    return response.json();
                })
                .then(() => {
                    // Close the update progress modal, bring back the add shows modal
                    that.closeProgressModal();
                    helperFunctions.showToast(decodeURIComponent(that.title) + ' has been added added to your shows!');
                    saveButton.disabled = false;
                });
        }
    }

    handleProgressUpdate(that) {
        if (that.mode == 1) {
            // Refresh the user's myShows
            that.myShows.refreshMyShowsItem(that.combinedTitleID, 'Updating progress...');
            that.scrollToCombinedTitleID = that.combinedTitleID;
        }
        // Save from /tv or /movie, close the modal and update the show progress on the screen
        if (that.mode == 3) {
            that.closeProgressModal();
            // Show the spinner with a custom message while we load the shows
            let spinner = document.querySelector('.spinner-wrapper');
            spinner.style.zIndex = '9999';
            let spinnerText = document.querySelector('#spinner-message');
            spinnerText.innerHTML = '<h4>Saving show progress...</h4>';
            spinner.classList.remove('hide');
            spinner.classList.add('show');

            let data = new FormData();
            data.set('appPlatform', 'web');
            data.set('combinedTitleID', that.combinedTitleID);
            data.set('method', 'getShowProgress');

            fetch(GATEWAY_DOMAIN + '/userActions/', {
                method: 'POST',
                credentials: 'include',
                body: data
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    spinner.classList.add('hide');
                    spinner.classList.remove('show');
                    spinner.style.zIndex = '-1';
                    let progressPercSpan = document.querySelector('#progressPercentage');
                    progressPercSpan.innerHTML = parseInt(data.userShowProgress * 100);

                    let userSeenSpan = document.querySelector('#userSeenCount');
                    userSeenSpan.innerHTML = data.userEpisodesSeen;

                    let progressBar = document.querySelector('.progressBar');
                    progressBar.style.width = parseInt(data.userShowProgress * 100) + '%';

                    let nextEpisodeContainer = document.querySelector('#nextEpisodeContainer_' + that.combinedTitleID);
                    let nextEpisodeInnerContainer = document.querySelector('#nextEpisode_' + that.combinedTitleID);
                    if (data.userNextEpisode.id != undefined && data.userNextEpisode.id != 0) {
                        // Make sure this is showing (only can be hidden if user is going backward from 100% progress)
                        nextEpisodeContainer.classList.add('d-block');
                        nextEpisodeContainer.classList.remove('d-none');
                        nextEpisodeInnerContainer.classList.add('d-block');
                        nextEpisodeInnerContainer.classList.remove('d-none');
                        let nextEpisodeImage = document.querySelector('#nextEpisodeImage_' + that.combinedTitleID);
                        // Update the mark as seen links data
                        let markAsSeenButtons = document.querySelectorAll('.markAsSeen');
                        markAsSeenButtons.forEach((item) => {
                            item.dataset.episodenumber = data.userNextEpisode.episode;
                            item.dataset.seasonnumber = data.userNextEpisode.season;
                        });

                        if (data.userNextEpisode.thumbnail !== undefined) {
                            nextEpisodeImage.src = data.userNextEpisode.thumbnail;
                        } else {
                            nextEpisodeImage.remove();
                        }

                        let nextEpisodeLink = document.querySelector('#nextEpisodeLink_' + that.combinedTitleID);
                        if (nextEpisodeLink) {
                            nextEpisodeLink.href = '/' + slugTypeFolder + '/' + slug + '/episode-guide/' + data.userNextEpisode.id + '/';
                        }

                        let nextEpisodeTitle = document.querySelector('#nextEpisodeTitle_' + that.combinedTitleID);
                        if (data.userNextEpisode.title !== undefined) {
                            nextEpisodeTitle.innerHTML = data.userNextEpisode.title;
                        }

                        let nextEpisodeSubtitle = document.querySelector('#nextEpisodeSubtitle_' + that.combinedTitleID);
                        if (data.userNextEpisode.episode !== undefined) {
                            nextEpisodeSubtitle.innerHTML = 'Season ' + data.userNextEpisode.season + ' Episode ' + data.userNextEpisode.episode;
                        }
                        let watchButtons = document.querySelectorAll('.episodeWatchButton');
                        if (data.userNextEpisode.consolidatedProviders.length) {
                            // Update the watchbutton
                            watchButtons.forEach((watchButton) => {
                                watchButton.outerHTML = buildWatchButton(data.userNextEpisode);
                            });
                        } else {
                            watchButtons.forEach((watchButton) => {
                                watchButton.classList.remove('d-block');
                                watchButton.classList.add('d-none');
                            });
                        }
                    } else {
                        // The user has no next episode so hide the next episode div
                        nextEpisodeContainer.classList.remove('d-block');
                        nextEpisodeContainer.classList.add('d-none');
                    }
                });
        }
        // Sent from the Up Next carousel on index.php, close the modal, and refresh the user's up next carousel
        if (that.mode == 4) {
            that.closeProgressModal();
            // Show the spinner with a custom message while we load the shows
            let spinner = document.querySelector('.spinner-wrapper');
            spinner.style.zIndex = '9999';
            let spinnerText = document.querySelector('#spinner-message');
            spinnerText.innerHTML = '<h4>Saving show progress...</h4>';
            spinner.classList.remove('hide');
            spinner.classList.add('show');

            let data = new FormData();
            data.set('appPlatform', 'web');
            data.set('combinedTitleID', that.combinedTitleID);

            fetch(GATEWAY_DOMAIN + '/fetchMyShowsTitle/', {
                method: 'POST',
                credentials: 'include',
                body: data
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    spinner.classList.add('hide');
                    spinner.classList.remove('show');
                    spinner.style.zIndex = '-1';

                    const title = data.titles[0];

                    const carouselItem = document.querySelector('.upNextEpisode[data-combinedtitleid="' + scrollToCombinedTitleID + '"]');

                    if (!title.userNextEpisode) {
                        carouselItem.remove();
                    } else {
                        const carouselItemHTML = buildMyShowsCarouselItem(title);
                        carouselItem.innerHTML = carouselItemHTML;
                    }

                    // Call the sort function on index to sort these results by the chosen sorting button
                    that.sortShowsIndex();
                    // Call the indexes scroll to title function
                    that.scrollToShow();
                });
        }
    }

    sortShowsIndex() {
        var sortButtons = document.querySelectorAll('.myShowsSortButton');
        var sortBy = 'recent';
        sortButtons.forEach((button) => {
            if (button.classList.contains('btn-primary')) {
                sortBy = button.dataset.sortby;
            }
        });

        var rows = document.querySelectorAll('.upNextEpisode');
        var rowsArray = Array.prototype.slice.call(rows, 0);
        var rowsSorted = this.sortRows(rowsArray, sortBy);
        var newRows = '';
        [].forEach.call(rowsSorted, (el) => {
            newRows += el.outerHTML;
        });
        var tableEl = document.querySelector('#myShowsNextEpisodes');
        tableEl.innerHTML = newRows;
        // This was a show update progress, let's scroll to that show after we refresh
        if (window.scrollToCombinedTitleID != '') {
            this.scrollToShow();
        }
    }

    scrollToShow() {
        let upNextEpisodes = document.querySelectorAll('.upNextEpisode');
        upNextEpisodes.forEach((element) => {
            if (element.dataset.combinedtitleid == window.scrollToCombinedTitleID) {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center'
                });
                window.scrollToCombinedTitleID = '';
            }
        });
    }

    sortRows(rowsArray, sortBy) {
        if (sortBy == 'alphabetical') {
            rowsArray.sort((a, b) => {
                if (b.getAttribute('data-title') > a.getAttribute('data-title')) return -1;
                if (a.getAttribute('data-title') < b.getAttribute('data-title')) return 1;
                return 0;
            });
        } else if (sortBy == 'recent') {
            rowsArray.sort((a, b) => {
                if (parseInt(a.getAttribute('data-lastwatched')) > parseInt(b.getAttribute('data-lastwatched'))) return -1;
                if (parseInt(b.getAttribute('data-lastwatched')) < parseInt(a.getAttribute('data-lastwatched'))) return 1;
                return 0;
            });
        } else if (sortBy == 'unwatched') {
            rowsArray.sort((a, b) => {
                if (parseInt(a.getAttribute('data-unwatchedepisodes')) > parseInt(b.getAttribute('data-unwatchedepisodes'))) return -1;
                if (parseInt(b.getAttribute('data-unwatchedepisodes')) < parseInt(a.getAttribute('data-unwatchedepisodes'))) return 1;
                return 0;
            });
        } else if (sortBy == 'total') {
            rowsArray.sort((a, b) => {
                if (parseInt(a.getAttribute('data-releasedepisodes')) > parseInt(b.getAttribute('data-releasedepisodes'))) return -1;
                if (parseInt(b.getAttribute('data-releasedepisodes')) < parseInt(a.getAttribute('data-releasedepisodes'))) return 1;
                return 0;
            });
        } else if (sortBy == 'newest') {
            rowsArray.sort((a, b) => {
                if (parseInt(a.getAttribute('data-airdatetimestamp')) > parseInt(b.getAttribute('data-airdatetimestamp'))) return -1;
                if (parseInt(b.getAttribute('data-airdatetimestamp')) < parseInt(a.getAttribute('data-airdatetimestamp'))) return 1;
                return 0;
            });
        }
        return rowsArray;
    }
}
