'use strict';
import { Browser } from './classes/Browser.js';
import { Dashboard } from './classes/Dashboard.js';
import { UpdateProgressModal } from './classes/UpdateProgressModal.js';
import { showModal, hideModal } from './helperFunctions.js';
import { GATEWAY_DOMAIN } from './constants.js';
import { buildMyShowsCarouselItem } from './helpers/buildMyShowsCarouselItem.js';

// eslint-disable-next-line no-unused-vars
/* global isAdmin, combinedUserID, isLoggedIn, navbarHidden, userType, formKey, returnUrl, WATCHMODE_CDN1 */
// eslint-disable-next-line no-unused-vars
/* global requestNotifications, hasEnoughRatings, launchLoginModal, browserControlPage, myProviders, sortBy, FsLightbox */

var myShowsClickedCombinedTitleID = '';
var myShowsClickedSlug = '';
var myShowsClickedSlugTypeFolder = '';
var myShowsClickedTitle = '';
var watchlistClickedCombinedTitleID = '';
var watchlistClickedSlugTypeFolder = '';
var watchlistClickedSlug = '';
var watchlistClickedTitle = '';
var scrollToCombinedTitleID = '';
const browser = new Browser('index');
const dashboard = new Dashboard();
const updateProgress = new UpdateProgressModal('', '', '');

document.addEventListener('DOMContentLoaded', () => {
    browser.setupChoices();
    browser.setupCriticScoreSlider();
    browser.setupUserScoreSlider();
    browser.setupYearSlider();
    // if (requestNotifications) {
    //     // Show the enable notification modal if we have never show the user the modal
    //     showModal('enableNotificationsModal');
    // }
    if (launchLoginModal) {
        window.location.replace('/login/');
    }
    if (isLoggedIn) {
        let progressSlider = document.getElementById('progressSlider');
        // handle the change of the update progress slider
        progressSlider.oninput = () => {
            // Find the episode
            updateProgress.changeEpisodeThumbnail(progressSlider.value, true);
        };
    }
    if (!isLoggedIn) {
        const rotatingHeadlines = document.getElementById('rotatingHeadlines');
        const counter = new Counter(rotatingHeadlines, 3000);
        counter.start();
    }
});

document.getElementById('saveShareForm').addEventListener('submit', (e) => {
    browser.saveShareFormSubmitHandler(e);
});

document.addEventListener('click', (event) => {
    browser.clickHandler(event);
    dashboard.clickHandler(event);
    if (event.target.matches('.watchlistSortButton')) {
        sortWatchlist(event.target.dataset.sortby, 'watchlistMovies');
        sortWatchlist(event.target.dataset.sortby, 'watchlistShows');
        // Highlight the button clicked
        let watchlistSortButtons = document.querySelectorAll('.watchlistSortButton');
        watchlistSortButtons.forEach((button) => {
            if (button == event.target) {
                button.classList.add('btn-primary');
                button.classList.remove('btn-secondary');
            } else {
                button.classList.add('btn-secondary');
                button.classList.remove('btn-primary');
            }
        });
    }

    if (event.target.matches('#notificationQuickLink')) {
        const dropdown = document.querySelector('#notificationDropdown');
        dropdown.setAttribute('aria-expanded', 'true');
        dropdown.classList.add('show');
        const dropdownLink = document.querySelector('.navbarDropdownMenuLink');
        dropdownLink.setAttribute('aria-expanded', 'true');
        dropdownLink.classList.add('show');
    }

    if (event.target.matches('#chooseForMeNext')) {
        let selectedButton = '';
        const chooseForMeButtons = document.querySelectorAll('.chooseForMeButton');
        chooseForMeButtons.forEach((button) => {
            if (button.classList.contains('btn-primary')) {
                selectedButton = button.id;
            }
        });
        window.location.href = '/watch-next/?selectedButton=' + selectedButton;
    }

    if (event.target.matches('.chooseForMeButton')) {
        const buttonClicked = event.target;
        if (!isLoggedIn) {
            showModal('needAccountModal');
            return;
        }
        if (!hasEnoughRatings) {
            showModal('notEnoughRatingsModal');
            return;
        }
        const chooseForMeButtons = document.querySelectorAll('.chooseForMeButton');
        // Clear all highlighted states
        chooseForMeButtons.forEach((button) => {
            if (button != buttonClicked) {
                button.classList.remove('btn-primary');
                button.classList.add('btn-secondary');
            }
        });
        // Toggle the state of the clicked button
        if (buttonClicked.classList.contains('btn-primary')) {
            buttonClicked.classList.add('btn-secondary');
            buttonClicked.classList.remove('btn-primary');
        } else {
            buttonClicked.classList.remove('btn-secondary');
            buttonClicked.classList.add('btn-primary');
        }
        const nextButton = document.querySelector('#chooseForMeNext');
        nextButton.classList.add('d-block');
        nextButton.classList.remove('d-none');
    }

    if (event.target.closest('.myShowsOptionsToggle')) {
        event.preventDefault();
        var toggleDiv = event.target.closest('.myShowsOptionsToggle');
        myShowsClickedCombinedTitleID = toggleDiv.dataset.combinedtitleid;
        myShowsClickedSlug = toggleDiv.dataset.slug;
        myShowsClickedSlugTypeFolder = toggleDiv.dataset.slugtypefolder;
        myShowsClickedTitle = decodeURIComponent(toggleDiv.dataset.title).replace(/\+/g, ' ');
        let myShowsOptionsModalLabel = document.querySelector('#myShowsOptionsModalLabel');
        myShowsOptionsModalLabel.innerHTML = myShowsClickedTitle;
        let myShowsOptionsEpisodeDetails = document.querySelector('#myShowsOptionsEpisodeDetails');
        myShowsOptionsEpisodeDetails.dataset.episodeid = toggleDiv.dataset.episodeid;
        showModal('myShowsOptionsModal');
    }

    // A button in the sort my shows modal was pressed, highlight that button, and call the function to re-sort the Up Next My Episodes table
    if (event.target.matches('.myShowsSortButton')) {
        var sortButtons = document.querySelectorAll('.myShowsSortButton');
        [].forEach.call(sortButtons, (el) => {
            el.classList.add('btn-secondary');
            el.classList.remove('btn-primary');
        });
        event.target.classList.add('btn-primary');
        event.target.classList.remove('btn-secondary');
        browser.sortShowsIndex();
    }

    // The details button in the myShows options modal was pressed, go to the title page
    if (event.target.id == 'myShowsOptionsDetails') {
        window.location.href = '/' + myShowsClickedSlugTypeFolder + '/' + myShowsClickedSlug + '/';
    }
    if (event.target.id == 'myShowsOptionsEpisodeDetails') {
        window.location.href = '/' + myShowsClickedSlugTypeFolder + '/' + myShowsClickedSlug + '/episode-guide/' + event.target.dataset.episodeid + '/';
    }

    if (event.target.id == 'myShowsOptionsRemove') {
        const removeData = new FormData();
        removeData.set('appPlatform', 'web');

        removeData.set('combinedTitleID', myShowsClickedCombinedTitleID);
        removeData.set('method', 'myShowsToggle');

        fetch(GATEWAY_DOMAIN + '/userActions/', {
            method: 'POST',
            credentials: 'include',
            body: removeData
        }).then((response) => {
            return response.json();
        });

        // Find the episode div and remove it
        let upNextEpisodes = document.querySelectorAll('.upNextEpisode');
        upNextEpisodes.forEach((upNextEpisode) => {
            if (upNextEpisode.dataset.combinedtitleid == myShowsClickedCombinedTitleID) {
                upNextEpisode.remove();
            }
        });
        hideModal('myShowsOptionsModal');
    }

    if (event.target.id == 'myShowsOptionsUpdateProgress') {
        hideModal('myShowsOptionsModal');
        updateProgress.updateValues(4, myShowsClickedCombinedTitleID, myShowsClickedTitle);
        updateProgress.showUpdateProgressModal();
        scrollToCombinedTitleID = myShowsClickedCombinedTitleID;
    }

    if (event.target.closest('.watchlistOptionsToggle')) {
        event.preventDefault();
        var showToggleDiv = event.target.closest('.watchlistOptionsToggle');
        watchlistClickedCombinedTitleID = showToggleDiv.dataset.combinedtitleid;
        watchlistClickedSlug = showToggleDiv.dataset.slug;
        watchlistClickedSlugTypeFolder = showToggleDiv.dataset.slugtypefolder;
        watchlistClickedTitle = decodeURIComponent(showToggleDiv.dataset.title).replace(/\+/g, ' ');

        let watchlistOptionsModalLabel = document.querySelector('#watchlistOptionsModalLabel');
        watchlistOptionsModalLabel.innerHTML = watchlistClickedTitle;
        let watchlistOptionsTrailer = document.querySelector('#watchlistOptionsPlayTrailer');
        // If there is no trailer ID, remove the play trailer button
        if (showToggleDiv.dataset.trailerid === undefined || showToggleDiv.dataset.trailerid == '') {
            watchlistOptionsTrailer.classList.add('d-none');
            watchlistOptionsTrailer.classList.remove('d-block');
        } else {
            watchlistOptionsTrailer.classList.remove('d-none');
            watchlistOptionsTrailer.classList.add('d-block');
            watchlistOptionsTrailer.dataset.trailerid = showToggleDiv.dataset.trailerid;
        }
        // Hide or show the "move to my shows" button depending on the title type
        let moveToShowsButton = document.getElementById('watchlistOptionsMoveToMyShows');
        if (showToggleDiv.dataset.type == 'movie') {
            moveToShowsButton.classList.add('d-none');
            moveToShowsButton.classList.remove('d-block');
        } else {
            moveToShowsButton.classList.remove('d-none');
            moveToShowsButton.classList.add('d-block');
        }
        showModal('watchlistOptionsModal');
    }

    // The details button in the watchlist options modal was pressed, go to the title page
    if (event.target.id == 'watchlistOptionsDetails') {
        window.location.href = '/' + watchlistClickedSlugTypeFolder + '/' + watchlistClickedSlug + '/';
    }

    // The play trailer button in the watchlist options modal was pressed, launch the trailer in lity
    if (event.target.id == 'watchlistOptionsPlayTrailer') {
        if (event.target.dataset.trailerid !== undefined && event.target.dataset.trailerid != '') {
            // eslint-disable-next-line no-undef
            const lightbox = new FsLightbox();
            lightbox.props.sources = ['https://www.youtube.com/watch?v=' + event.target.dataset.trailerid];
            lightbox.open();
        }
    }

    // The "Move to my shows" button was pressed in the watchlist options modal
    if (event.target.id == 'watchlistOptionsMoveToMyShows') {
        var moveData = new FormData();
        moveData.set('appPlatform', 'web');
        moveData.set('method', 'moveWatchlistToMyShows');
        moveData.set('combinedTitleID', watchlistClickedCombinedTitleID);

        fetch(GATEWAY_DOMAIN + '/userActions/', {
            method: 'POST',
            credentials: 'include',
            body: moveData
        }).then((response) => {
            return response.json();
        });

        let watchlistPosters = document.querySelectorAll('.upNextPoster');
        watchlistPosters.forEach((poster) => {
            if (poster.dataset.combinedtitleid == watchlistClickedCombinedTitleID) {
                poster.remove();
            }
        });
        // hide the modal
        hideModal('watchlistOptionsModal');
    }

    if (event.target.id == 'powerBrowseSubmit') {
        browser.powerBrowseSubmit();
    }

    // The remove button was presed in the watchlist options modal, call the userActions API then remove the cell
    if (event.target.id == 'watchlistOptionsRemove') {
        const data = new FormData();
        data.set('appPlatform', 'web');
        data.set('method', 'watchlistToggle');
        data.set('combinedTitleID', watchlistClickedCombinedTitleID);

        fetch(GATEWAY_DOMAIN + '/userActions/', {
            method: 'POST',
            credentials: 'include',
            body: data
        }).then((response) => {
            return response.json();
        });

        const watchlistPosters = document.querySelectorAll('.upNextPoster');
        watchlistPosters.forEach((poster) => {
            if (poster.dataset.combinedtitleid == watchlistClickedCombinedTitleID) {
                poster.remove();
            }
        });
        // hide the modal
        hideModal('watchlistOptionsModal');
    }

    if (event.target.matches('.markAsSeen')) {
        event.preventDefault();
        if (event.target.classList.contains('.disabledLink') || event.target.closest('.disabledLink')) {
            return;
        }
        event.target.classList.add('disabledLink');
        var spinner = document.querySelector('.spinner-wrapper');
        var spinnerText = document.querySelector('#spinner-message');
        spinnerText.innerHTML = '<h4>Updating show progress...</h4>';
        spinner.classList.remove('hide');
        spinner.classList.add('d-block');
        spinner.style.zIndex = '9999';

        scrollToCombinedTitleID = event.target.dataset.combinedtitleid;
        var markData = new FormData();
        markData.set('appPlatform', 'web');
        markData.set('combinedTitleID', event.target.dataset.combinedtitleid);
        markData.set('episodeID', event.target.dataset.episodeid);
        markData.set('episodeNumber', event.target.dataset.episodenumber);
        markData.set('seasonNumber', event.target.dataset.seasonnumber);
        markData.set('seen', 0);
        markData.set('method', 'toggleEpisodeStatus');

        // Save the mark as seen status, then refresh the carousel HTML
        fetch(GATEWAY_DOMAIN + '/userActions/', {
            method: 'POST',
            credentials: 'include',
            body: markData
        })
            .then((response) => {
                return response.json();
            })
            .then(() => {
                const epData = new FormData();
                epData.set('appPlatform', 'web');
                epData.set('combinedTitleID', scrollToCombinedTitleID);
                fetch(GATEWAY_DOMAIN + '/fetchMyShowsTitle/', {
                    method: 'POST',
                    credentials: 'include',
                    body: epData
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        event.target.classList.remove('disabledLink');
                        spinner.classList.add('d-none');
                        spinner.classList.remove('d-block');
                        spinner.style.zIndex = '-1';

                        const title = data.titles[0];

                        const carouselItem = document.querySelector('.upNextEpisode[data-combinedtitleid="' + scrollToCombinedTitleID + '"]');

                        if (!title.userNextEpisode) {
                            carouselItem.remove();
                        } else {
                            const carouselItemHTML = buildMyShowsCarouselItem(title);
                            carouselItem.innerHTML = carouselItemHTML;
                        }
                     
                        // Scroll to the combinedTitleID
                        updateProgress.scrollToShow();
                    });
            });
    }
    // if (event.target.id == 'enableNotificationsBtn') {
    //     if (firebase.messaging.isSupported()) {
    //         const messaging = firebase.messaging();
    //         messaging
    //             .requestPermission()
    //             .then(() => {
    //                 console.log('Notification permission granted.');

    //                 // get the token in the form of promise
    //                 return messaging.getToken();
    //             })
    //             .then((token) => {
    //                 const data = new FormData();
    //                 data.set('appPlatform', 'web');
    //                 data.set('deviceToken', token);
    //                 data.set('deviceName', 'browser');
    //                 data.set('method', 'saveDeviceToken');
    //                 data.set('appPlatform', 'web');
    //                 fetch(GATEWAY_DOMAIN + '/userActions/', {
    //                     method: 'POST',
    //                     credentials: 'include',
    //                     body: data
    //                 }).then((response) => {
    //                     return response.json();
    //                 });
    //             })
    //             .then(() => {
    //                 const data = new FormData();
    //                 data.set('appPlatform', 'web');
    //                 data.set('notificationsEnabled', 1);
    //                 data.set('method', 'saveWebNotificationRequest');
    //                 fetch(GATEWAY_DOMAIN + '/userActions/', {
    //                     method: 'POST',
    //                     credentials: 'include',
    //                     body: data
    //                 }).then((response) => {
    //                     return response.json();
    //                 });
    //             })
    //             .catch((err) => {
    //                 console.log('Unable to get permission to notify.', err);
    //             });
    //     }
    //     hideModal('enableNotificationsModal');
    // }
    if (event.target.id == 'dontEnableNotifications') {
        hideModal('enableNotificationsModal');
        var data1 = new FormData();
        data1.set('appPlatform', 'web');
        data1.set('notificationsEnabled', 0);
        data1.set('method', 'saveWebNotificationRequest');
        fetch(GATEWAY_DOMAIN + '/userActions/', {
            method: 'POST',
            credentials: 'include',
            body: data1
        }).then((response) => {
            return response.json();
        });
    }
});

function sortWatchlist(sortBy, table) {
    var itemClass = '';
    var tableID = '';
    if (table == 'watchlistMovies') {
        itemClass = '.watchlistMovie';
        tableID = '#watchlistMovies';
    } else if (table == 'watchlistShows') {
        itemClass = '.watchlistShow';
        tableID = '#watchlistShows';
    }
    let watchlistMovies = document.querySelectorAll(itemClass);
    var watchlistArray = Array.prototype.slice.call(watchlistMovies, 0);
    var itemsSorted = sortWatchlistItems(watchlistArray, sortBy);
    var newItems = '';
    [].forEach.call(itemsSorted, (el) => {
        newItems += el.outerHTML;
    });
    var watchlistTable = document.querySelector(tableID);
    watchlistTable.innerHTML = newItems;
}

function sortWatchlistItems(watchlistArray, sortBy) {
    if (sortBy == 'userRatingDesc') {
        watchlistArray.sort((a, b) => {
            if (b.getAttribute('data-userrating') < a.getAttribute('data-userrating')) return -1;
            if (a.getAttribute('data-userrating') > b.getAttribute('data-userrating')) return 1;
            return 0;
        });
    } else if (sortBy == 'dateAddedNew') {
        watchlistArray.sort((a, b) => {
            if (parseInt(a.getAttribute('data-added')) > parseInt(b.getAttribute('data-added'))) return -1;
            if (parseInt(b.getAttribute('data-added')) < parseInt(a.getAttribute('data-added'))) return 1;
            return 0;
        });
    } else if (sortBy == 'dateAddedOld') {
        watchlistArray.sort((a, b) => {
            if (parseInt(a.getAttribute('data-added')) < parseInt(b.getAttribute('data-added'))) return -1;
            if (parseInt(b.getAttribute('data-added')) > parseInt(a.getAttribute('data-added'))) return 1;
            return 0;
        });
    } else if (sortBy == 'releaseDateDesc') {
        watchlistArray.sort((a, b) => {
            if (parseInt(a.getAttribute('data-releasedate')) > parseInt(b.getAttribute('data-releasedate'))) return -1;
            if (parseInt(b.getAttribute('data-releasedate')) < parseInt(a.getAttribute('data-releasedate'))) return 1;
            return 0;
        });
    }
    return watchlistArray;
}

function Counter(elem, delay) {
    let value = parseInt(elem.dataset.value, 10);
    const titles = ['BINGE WATCHERS', 'FINDING MOVIES', 'CORD CUTTERS', 'NETFLIX ADDICTS', 'FINDING TV SHOWS', 'RECOMMENDATIONS'];

    function updateDisplay(value) {
        elem.innerHTML = value;
    }

    function run() {
        value += 1;
        if (value == titles.length) value = 0;

        elem.dataset.value = value;
        updateDisplay(titles[value]);
    }

    function start() {
        window.setInterval(run, delay);
    }
    this.start = start;
}
