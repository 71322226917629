import Choices from 'choices.js';
import { GATEWAY_DOMAIN, WATCHMODE_CDN1 } from '../constants.js';
import { buildWatchButton } from '../helpers/buildWatchButton.js';
import { buildRatingOutput } from '../helpers/buildRatingOutput.js';
import noUiSlider from 'nouislider';
// eslint-disable-next-line no-unused-vars
/* global tag, savedSearchID, noUiSlider, doSelectAll, browserControlPage, myProviders, sortBy:true, peoplePrepop, languagePrePop, tagsPrePop, studiosPrePop */

export class Browser {
    constructor(page) {
        this.page = page;
        this.rpp = 30;
        this.offset = 0;
        this.endOfResults = false;
        this.isUpdating = false;
        this.timeout = null;
        this.selectedProviders = [];
    }

    async setupChoices() {
        this.languageChoices = new Choices('#languageChoices', {
            delimiter: ',',
            removeItemButton: true,
            duplicateItemsAllowed: false,
            searchPlaceholderValue: 'Search for a language'
        });
        this.peopleChoices = new Choices('#peopleChoices', {
            delimiter: ',',
            removeItemButton: true,
            duplicateItemsAllowed: false,
            noChoicesText: 'Type a persons name'
        });
        this.tagsChoices = new Choices('#tagsChoices', {
            delimiter: ',',
            removeItemButton: true,
            duplicateItemsAllowed: false,
            noChoicesText: 'Type to search, or choose from the list.'
        });
        this.studiosChoices = new Choices('#studiosChoices', {
            delimiter: ',',
            removeItemButton: true,
            duplicateItemsAllowed: false,
            noChoicesText: 'Type to search, or choose from the list.'
        });
        if (typeof peoplePrepop !== 'undefined' && peoplePrepop.length > 0) {
            this.peopleChoices.setValue(peoplePrepop);
        }

        const config = await fetch(GATEWAY_DOMAIN + '/fetchConfig/', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                return response.json();
            });


        if (config.languages) {
            this.languageChoices.setChoices(() => {
                return config.languages.map((language) => {
                    return {
                        label: language.englishName,
                        value: language.id
                    };
                });
            });
        }

        if (config.tags) {
            this.tagsChoices.setChoices(() => {
                return config.tags.map((tag) => {
                    return {
                        label: tag.name,
                        value: tag.id
                    };
                });
            });
        }

        if (config.productionCompanies) {
            this.studiosChoices.setChoices(() => {
                return config.productionCompanies.map((studio) => {
                    return {
                        label: studio.name,
                        value: studio.id
                    };
                });
            });
        }

        if (typeof languagePrePop !== 'undefined' && languagePrePop.length > 0) {
            this.languageChoices.setValue(languagePrePop);
        }

        if (typeof tagsPrePop !== 'undefined' && tagsPrePop.length > 0) {
            let tagsValues = []
            for (const tagId of tagsPrePop) {
                const tag = config.tags.find((t) => t.id === tagId);
                if (tag) tagsValues.push({
                    label: tag.name,
                    value: tag.id
                });
            }

            if (tagsValues.length > 0) {
                this.tagsChoices.setValue(tagsValues);
            }
        }

        if (typeof studiosPrePop !== 'undefined' && studiosPrePop.length > 0) {
            let studioValues = []
            for (const studioId of studiosPrePop) {
                const studio = config.productionCompanies.find((t) => t.id === studioId);
                if (studio) studioValues.push({
                    label: studio.name,
                    value: studio.id
                });
            }

            if (studioValues.length > 0) {
                this.studiosChoices.setValue(studioValues);
            }
        }

        const that = this;
        this.languageChoices.passedElement.element.addEventListener('removeItem', () => {
            that.updateLanguageLabel();
        });

        this.languageChoices.passedElement.element.addEventListener('addItem', () => {
            that.updateLanguageLabel();
        });

        // Fetch the people options when the user types in the input
        this.peopleChoices.passedElement.element.addEventListener('search', (value) => {
            clearTimeout(this.timeout);
            this.peopleChoices.clearChoices();

            // wait a half a second to make sure they finish typing
            this.timeout = setTimeout(() => {
                if (value.detail.value.length > 2) {
                    const body = new FormData();
                    body.set('appPlatform', 'web');
                    body.set('term', value.detail.value);
                    this.peopleChoices.setChoices(async () => {
                        const response = await fetch(GATEWAY_DOMAIN + '/peopleSearch/', {
                            method: 'POST',
                            body
                        });
                        const data = await response.json();
                        if (data) {
                            return data.results.map((person) => {
                                return {
                                    label: person.label,
                                    value: person.id
                                };
                            });
                        }
                    });
                }
            }, 800);
        });
        this.peopleChoices.passedElement.element.addEventListener('addItem', () => {
            that.updatePeopleOutput();
        });
        this.peopleChoices.passedElement.element.addEventListener('removeItem', () => {
            that.updatePeopleOutput();
        });

        this.tagsChoices.passedElement.element.addEventListener('addItem', () => {
            that.updateTagsOutput();
        });
        this.tagsChoices.passedElement.element.addEventListener('removeItem', () => {
            that.updateTagsOutput();
        });
        this.studiosChoices.passedElement.element.addEventListener('addItem', () => {
            that.updateStudiosOutput();
        });
        this.studiosChoices.passedElement.element.addEventListener('removeItem', () => {
            that.updateStudiosOutput();
        });
    }

    createPowerSearchResultDiv(title, bgClass) {
        const { combinedTitleID, poster, typeOutput, userRating, userInterest, consolidatedProviders, genreOutput, isReleasedYet, language, year, releaseDate, wmRating, wmCriticScore, inCustomLists, watchlistStatus, myShowsStatus } = title;

        const wmRatingDiv = wmRating ? buildRatingOutput(wmRating, 1) : '';
        const criticScoreDiv = wmCriticScore ? buildRatingOutput(wmCriticScore, 2) : '';
        const releasedDaysAgo = releaseDate ? Math.floor((new Date() - new Date(releaseDate)) / (1000 * 60 * 60 * 24)) : null;
        const newBadge = releasedDaysAgo && releasedDaysAgo < 20 ? '<span class="tealBorderedBadge">NEW</span>' : '';
        const genreOutputDiv = genreOutput ? `<span class="mb-1 textRegularBold lightGrey">${genreOutput}</span>` : '';
        const languageOutputDiv = language && language !== 'English' ? `<span class="textRegular lightGrey">&nbsp;-&nbsp;${language}</span>` : '';
        const titleAlphaOnly = title.title.replace(/[^a-zA-Z0-9]/g, '');
        const inCustomListsJson = JSON.stringify(inCustomLists);

        let watchlistCount = inCustomLists.length + watchlistStatus;
        let watchlistButton = watchlistCount > 0 && myShowsStatus == 0 ? `<img src="${WATCHMODE_CDN1}/icons/check.svg" alt="On Watchlist" class="lml lmlIcon" width="34" height="34" data-combinedtitleid="${combinedTitleID}" data-titletype="${title.type}" data-title="${titleAlphaOnly}" data-incustomlists="${inCustomListsJson}" data-watchliststatus="${watchlistStatus}" data-myshowsstatus="${myShowsStatus}">` : ` <img src="${WATCHMODE_CDN1}/icons/plus.svg" class="lml lmlIcon" alt="Add to Watchlist" width="34" height="34" data-combinedtitleid="${combinedTitleID}" data-title="${titleAlphaOnly}" data-titletype="${title.type}" data-incustomlists="${inCustomListsJson}" data-watchliststatus="${watchlistStatus}" data-myshowsstatus="${myShowsStatus}">`;
        watchlistButton = myShowsStatus === 1 ? `<img src="${WATCHMODE_CDN1}/icons/tv-white.svg" class="mt-1 lml lmlIcon" alt="On My Shows" width="24" height="24" data-combinedtitleid="${combinedTitleID}" data-titletype="${title.type}" data-title="${titleAlphaOnly}" data-incustomlists="${inCustomListsJson}" data-watchliststatus="${watchlistStatus}" data-myshowsstatus="${myShowsStatus}">` : watchlistButton;

        let tileWatchlistCountDisplayClass = watchlistCount > 1 && myShowsStatus === 0 ? 'd-block' : 'd-none';
        let tileWatchlistButtonDisplayClass = watchlistCount > 0 && myShowsStatus === 0 ? 'watchlistBgGreen' : 'watchlistBgBlack';
        tileWatchlistButtonDisplayClass = myShowsStatus === 1 ? 'watchlistBgOrange' : tileWatchlistButtonDisplayClass;

        const providersCountLink = consolidatedProviders.length > 1 ? `<a href="/${title.slugTypeFolder}/${title.slug}/" class="teal textSmall">${consolidatedProviders.length}&nbsp;streaming sources</a>` : '';
        const watchButton = buildWatchButton(title, this.selectedProviders, false);

        const statusButtonData = this.getTileStatusButton(userRating, userInterest);

        const html = `<div class="col-lg-4 ${bgClass} p-0">
                <div class="brPos ptd pts" data-ctid="${combinedTitleID}" data-title="${titleAlphaOnly}" data-watchliststatus="${watchlistStatus}" data-incustomlists="${inCustomListsJson}" data-titletype="${title.type}" data-myshowsstatus="${myShowsStatus}" data-isreleasedyet="${+isReleasedYet}">
                    <a target="_blank" rel="noopener" href="/${title.slugTypeFolder}/${title.slug}/">
                        <img src="${poster}" alt="${title.title}" style="width: 140px;">
                    </a>
                    <div class="tileWatchlistCount lml ${tileWatchlistCountDisplayClass}">${watchlistCount}</div>
                    <div class="tileWatchlistButton lml text-center ${tileWatchlistButtonDisplayClass}">
                        ${watchlistButton}
                    </div>
                    <div class="tileStatusButton ${statusButtonData.statusIconBG}" data-ctid="${combinedTitleID}">${statusButtonData.statusIcon}</div>
                </div>
                <div class="brCon">
                    <h5 style="display:inline;" class="white">
                        <a target="_blank" rel="noopener" href="/${title.slugTypeFolder}/${title.slug}/">${title.title}</a>
                    </h5>
                    ${newBadge}
                    <div class="mb-1 textRegularBold lightGrey"> ${typeOutput}&nbsp;(${year})
                    </div>
                    <div class="mb-1">
                        ${wmRatingDiv}
                        ${criticScoreDiv}                        
                    </div>
                    <div class="mb-1">
                        ${genreOutputDiv}
                        ${languageOutputDiv}
                    </div>
                    <div class="mt-2 mb-1 w-100 text-center"> ${watchButton}
                    </div>
                    <div class="mt-2 mb-1 pe-1 w-100 text-end">
                        ${providersCountLink}
                    </div>
                </div>
            </div>`;

        return html;
    }

    getTileStatusButton(userRating, interest) {
        const statusButtonDetails = {
            statusIcon: '<img src="' + WATCHMODE_CDN1 + '/icons/eye-slash-fill.svg" alt="" width="24" height="24">',
            statusIconClass: '',
            statusIconBG: ''
        };

        // Set the status icon based on the user status
        if (userRating !== null) {
            if (userRating === 10) {
                statusButtonDetails.statusIcon = '<img src="' + WATCHMODE_CDN1 + '/icons/trophy.svg" alt="" width="24" height="24">';
            } else if (userRating === 8) {
                statusButtonDetails.statusIcon = '<img src="' + WATCHMODE_CDN1 + '/icons/heart.svg" alt="" width="24" height="24">';
            } else if (userRating === 7) {
                statusButtonDetails.statusIcon = '<img src="' + WATCHMODE_CDN1 + '/icons/hand-thumbs-up.svg" alt="" width="24" height="24">';
            } else if (userRating === 5) {
                statusButtonDetails.statusIcon = '<img src="' + WATCHMODE_CDN1 + '/icons/emoji-expressionless.svg" alt="" width="24" height="24">';
            } else if (userRating === 4) {
                statusButtonDetails.statusIcon = '<img src="' + WATCHMODE_CDN1 + '/icons/emoji-frown.svg" alt="" width="24" height="24">';
            } else if (userRating === 2) {
                statusButtonDetails.statusIcon = '<img src="' + WATCHMODE_CDN1 + '/icons/hand-thumbs-down.svg" alt="" width="24" height="24">';
            }
        }

        // Set the background class color to match the status
        if (userRating !== null) {
            if (userRating === 10) {
                statusButtonDetails.statusIconBG = 'tileStatusButtonBgGold';
            } else if (userRating === 8) {
                statusButtonDetails.statusIconBG = 'tileStatusButtonBgDarkGreen';
            } else if (userRating === 7) {
                statusButtonDetails.statusIconBG = 'tileStatusButtonBgLightGreen';
            } else if (userRating === 5) {
                statusButtonDetails.statusIconBG = 'tileStatusButtonBgOrange';
            } else if (userRating === 4) {
                statusButtonDetails.statusIconBG = 'tileStatusButtonBgBrown';
            } else if (userRating === 2) {
                statusButtonDetails.statusIconBG = 'tileStatusButtonBgRed';
            }
        }

        // Set the default unseen icon
        if (!statusButtonDetails.statusIconBG) {
            statusButtonDetails.statusIconBG = 'tileStatusButtonBgBlack';
        }

        // Check interest and update status icon and background class
        if (interest !== null && !statusButtonDetails.statusIcon && !statusButtonDetails.statusIconBG) {
            if (interest === 1) {
                statusButtonDetails.statusIcon = '<img src="' + WATCHMODE_CDN1 + '/icons/hand-thumbs-up.svg" alt="" width="24" height="24">';
                statusButtonDetails.statusIconBG = 'tileStatusButtonBgDarkGreen';
            } else if (interest === 2) {
                statusButtonDetails.statusIcon = '<img src="' + WATCHMODE_CDN1 + '/icons/emoji-expressionless.svg" alt="" width="24" height="24">';
                statusButtonDetails.statusIconBG = 'tileStatusButtonBgOrange';
            } else if (interest === 3) {
                statusButtonDetails.statusIcon = '<img src="' + WATCHMODE_CDN1 + '/icons/hand-thumbs-down.svg" alt="" width="24" height="24">';
                statusButtonDetails.statusIconBG = 'tileStatusButtonBgRed';
            }
        }

        // Set the default unseen icon
        if (!statusButtonDetails.statusIconBG) {
            statusButtonDetails.statusIconBG = 'tileStatusButtonBgBlack';
        }

        // Set the icon class for width and padding depending on which icon it is
        statusButtonDetails.statusIconClass = statusButtonDetails.statusIcon.includes('eye-slash-fill') ? 'tileStatusIconNarrow' : 'tileStatusIconNarrow';

        return statusButtonDetails;
    }

    setupUserScoreSlider(userScoreSliderSelected = [0, 10]) {
        var that = this;
        this.userScoreSlider = document.getElementById('userScoreSlider');
        noUiSlider.create(this.userScoreSlider, {
            start: userScoreSliderSelected,
            connect: true,
            range: {
                min: 0.0,
                max: 10.0
            }
        });
        this.userScoreSlider.noUiSlider.on('slide', (values) => {
            var outputLow = document.getElementById('userScoreOutputLow');
            var outputHigh = document.getElementById('userScoreOutputHigh');
            var scoreLow = Math.round(parseFloat(values[0]) * 10) / 10;
            var scoreHigh = Math.round(parseFloat(values[1]) * 10) / 10;
            outputLow.innerHTML = scoreLow;
            outputHigh.innerHTML = scoreHigh;
            var outputButton = document.getElementById('userScoreFilter');
            outputButton.innerHTML = 'User Score: ' + scoreLow + ' - ' + scoreHigh;
            if (values[0] == 0 && values[1] == 10) {
                outputButton.classList.remove('btn-primary');
                outputButton.classList.add('btn-secondary');
            } else {
                outputButton.classList.add('btn-primary');
                outputButton.classList.remove('btn-secondary');
            }
        });
        this.userScoreSlider.noUiSlider.on('end', () => {
            that.endOfResults = false;
            if (that.page == 'browse') {
                that.updateTitles(1);
            }
        });
    }

    setupCriticScoreSlider(criticScoreSelected = [0, 100]) {
        var that = this;
        this.criticScoreSlider = document.getElementById('criticScoreSlider');
        noUiSlider.create(this.criticScoreSlider, {
            start: criticScoreSelected,
            connect: true,
            range: {
                'min': 0,
                'max': 100
            }
        });

        this.criticScoreSlider.noUiSlider.on('end', () => {
            if (that.page == 'browse') {
                that.updateTitles(1);
            }
        });
        this.criticScoreSlider.noUiSlider.on('slide', (values) => {
            var outputLow = document.getElementById('criticScoreOutputLow');
            var outputHigh = document.getElementById('criticScoreOutputHigh');
            var scoreLow = parseInt(values[0]);
            var scoreHigh = parseInt(values[1]);
            outputLow.innerHTML = scoreLow;
            outputHigh.innerHTML = scoreHigh;
            var outputButton = document.getElementById('criticScoreFilter');
            outputButton.innerHTML = 'Critic Score: ' + scoreLow + ' - ' + scoreHigh;
            if (values[0] == 0 && values[1] == 100) {
                outputButton.classList.remove('btn-primary');
                outputButton.classList.add('btn-secondary');
            } else {
                outputButton.classList.add('btn-primary');
                outputButton.classList.remove('btn-secondary');
            }
        });
    }

    setupYearSlider(yearsSelected = [1980, 2030]) {
        var that = this;
        this.yearSlider = document.getElementById('yearSlider');
        const d = new Date();
        const n = d.getFullYear();
        noUiSlider.create(this.yearSlider, {
            start: yearsSelected,
            connect: true,
            range: {
                min: 1900,
                max: n
            }
        });
        this.yearSlider.noUiSlider.on('slide', (values) => {
            var outputLow = document.getElementById('yearOutputLow');
            var outputHigh = document.getElementById('yearOutputHigh');
            var yearLow = parseInt(values[0]);
            var yearHigh = parseInt(values[1]);
            outputLow.innerHTML = yearLow;
            outputHigh.innerHTML = yearHigh;
            var outputButton = document.getElementById('yearsFilter');
            outputButton.innerHTML = 'Years: ' + yearLow + ' - ' + yearHigh;
            if (values[0] == 1980 && values[1] == n) {
                outputButton.classList.remove('btn-primary');
                outputButton.classList.add('btn-secondary');
            } else {
                outputButton.classList.add('btn-primary');
                outputButton.classList.remove('btn-secondary');
            }
        });
        this.yearSlider.noUiSlider.on('end', () => {
            if (that.page == 'browse') {
                that.updateTitles(1);
            }
        });
    }

    updateTitles(resetOffset, isInfiniteScroll) {
        isInfiniteScroll = typeof isInfiniteScroll !== 'undefined' ? isInfiniteScroll : false;
        if (resetOffset == 1) {
            this.offset = 0;
        }
        var providerIDs = [];

        const providerLogos = document.querySelectorAll('.provider_logo-selected');
        providerLogos.forEach((providerLogo) => {
            providerIDs.push(providerLogo.id);
        });

        this.selectedProviders = providerIDs;

        var peopleIDs = [];
        this.peopleChoices.getValue().forEach((peopleToken) => {
            peopleIDs.push(peopleToken.value);
        });

        var userRatingOutputLow = document.getElementById('userScoreOutputLow');
        var userRatingOutputHigh = document.getElementById('userScoreOutputHigh');
        var userRatingLow = Math.round(parseFloat(userRatingOutputLow.innerHTML) * 10) / 10;
        var userRatingHigh = Math.round(parseFloat(userRatingOutputHigh.innerHTML) * 10) / 10;

        var criticScoreOutputLow = document.getElementById('criticScoreOutputLow');
        var criticScoreOutputHigh = document.getElementById('criticScoreOutputHigh');
        var criticScoreLow = parseInt(criticScoreOutputLow.innerHTML);
        var criticScoreHigh = parseInt(criticScoreOutputHigh.innerHTML);

        var yearOutputLow = document.getElementById('yearOutputLow');
        var yearOutputHigh = document.getElementById('yearOutputHigh');
        var yearLow = parseInt(yearOutputLow.innerHTML);
        var yearHigh = parseInt(yearOutputHigh.innerHTML);

        var genreIDs = [];
        var foundNotSelected = false;
        var genreButtons = document.querySelectorAll('.genreButtons');
        genreButtons.forEach((genreButton) => {
            if (genreButton.classList.contains('btn-primary')) {
                genreIDs.push(genreButton.dataset.id);
            } else {
                foundNotSelected = true;
            }
        });

        // If we didn't find a single button not selected, empty the genre array to indicate "All"
        if (!foundNotSelected) {
            genreIDs = [];
        }

        var languages = [];
        this.languageChoices.getValue().forEach((peopleToken) => {
            languages.push(peopleToken.value);
        });

        var tags = [];
        this.tagsChoices.getValue().forEach((tagToken) => {
            console.log(tagToken);
            tags.push(tagToken.value);
        });

        var studios = [];
        this.studiosChoices.getValue().forEach((studioToken) => {
            studios.push(studioToken.value);
        });

        var myServicesSelected = document.getElementById('servicesMy').classList.contains('btn-primary') ? 1 : 0;

        var titleType = '';
        var filterTypes = document.querySelectorAll('.filterType');
        filterTypes.forEach((filterType) => {
            if (filterType.classList.contains('btn-primary')) {
                titleType = filterType.dataset.type;
            }
        });

        var allServicesSelected = document.getElementById('servicesAll').classList.contains('btn-primary') ? 1 : 0;
        var freeServicesSelected = document.getElementById('servicesFree').classList.contains('btn-primary') ? 1 : 0;
        var rentServicesSelected = document.getElementById('servicesRent').classList.contains('btn-primary') ? 1 : 0;
        var buyServicesSelected = document.getElementById('servicesBuy').classList.contains('btn-primary') ? 1 : 0;

        var browseResults = document.getElementById('browseAjaxResults');
        var browseMessage = document.getElementById('browseMessage');
        var browserFilters = document.getElementById('browserFilters');

        if (providerIDs.length == 0 && allServicesSelected == 0 && myServicesSelected == 0 && freeServicesSelected == 0 && rentServicesSelected == 0 && buyServicesSelected == 0) {
            browseResults.innerHTML = '<div style="width: 100%; text-align: center; margin-top: 50px; margin-bottom: 50px;"><img src="' + WATCHMODE_CDN1 + '/misc_images/icons/emojiFrown.png" style=" width: 95px; height: 95px;"><BR>Whoops! Please select at least one provider or provider type.</div>';
            return false;
        }

        var hideNonEn = document.getElementById('hideNonEn').checked ? 1 : 0;
        var hideShort = document.getElementById('hideShort').checked ? 1 : 0;
        var hideSeen = document.getElementById('hideSeen').checked ? 1 : 0;
        var hideKids = document.getElementById('hideKids').checked ? 1 : 0;
        var hideMature = document.getElementById('hideMature').checked ? 1 : 0;
        var hideAnimation = document.getElementById('hideAnimation').checked ? 1 : 0;
        var hideRatedNegative = document.getElementById('hideRatedNegative').checked ? 1 : 0;
        var hideFewerThanRatings = document.getElementById('hideFewerThanRatings').checked ? 1 : 0;
        var hideFewerThanRatingsCount = document.getElementById('hideFewerThanRatingsCount').value;
        var hideDocs = document.getElementById('hideDocs').checked ? 1 : 0;

        this.isUpdating = true;
        var searchText = !isInfiniteScroll ? 'Searching for titles...' : 'Loading more results...';
        if (!isInfiniteScroll) {
            // Clear the results
            browseResults.innerHTML = '';
        }
        browseMessage.innerHTML = '<div style="text-align: center; padding-top: 50px; padding-bottom: 50px;"><h4 class="workingOnIt">' + searchText + '</h4></div>';
        browseMessage.classList.add('d-block');
        browseMessage.classList.remove('d-none');
        browserFilters.classList.add('disabledElement');

        var data = new FormData();
        data.set('appPlatform', 'web');
        providerIDs.forEach((providerID, i) => {
            data.set('providers[' + i + ']', providerID);
        });
        genreIDs.forEach((genreID, i) => {
            data.set('genres[' + i + ']', genreID);
        });
        languages.forEach((language, i) => {
            data.set('languages[' + i + ']', language);
        });
        peopleIDs.forEach((peopleID, i) => {
            data.set('people[' + i + ']', peopleID);
        });
        tags.forEach((tag, i) => {
            data.set('tags[' + i + ']', tag);
        });
        studios.forEach((studio, i) => {
            data.set('productionCompanies[' + i + ']', studio);
        });
        data.set('myServicesSelected', myServicesSelected);
        data.set('allServicesSelected', allServicesSelected);
        data.set('yearLow', yearLow);
        data.set('yearHigh', yearHigh);
        data.set('userRatingLow', userRatingLow);
        data.set('userRatingHigh', userRatingHigh);
        data.set('criticScoreLow', criticScoreLow);
        data.set('criticScoreHigh', criticScoreHigh);
        data.set('rentServicesSelected', rentServicesSelected);
        data.set('buyServicesSelected', buyServicesSelected);
        data.set('freeServicesSelected', freeServicesSelected);
        data.set('hideNonEn', hideNonEn);
        data.set('hideShort', hideShort);
        data.set('hideSeen', hideSeen);
        data.set('hideKids', hideKids);
        data.set('hideMature', hideMature);
        data.set('hideRatedNegative', hideRatedNegative);
        data.set('hideAnimation', hideAnimation);
        data.set('hideFewerThanRatings', hideFewerThanRatings);
        data.set('hideFewerThanRatingsCount', hideFewerThanRatingsCount);
        data.set('hideDocs', hideDocs);
        data.set('titleType', titleType);
        data.set('region', document.getElementById('navRegionFlag').dataset.region);
        data.set('sortBy', sortBy);
        data.set('resultsPerPage', this.rpp);
        data.set('offset', this.offset);
        if (tag) data.set('tag', tag);
        if (savedSearchID) data.set('savedSearchID', savedSearchID);

        // Save the mark as seen status, then refresh the carousel HTML
        fetch(GATEWAY_DOMAIN + '/powerSearch/', {
            method: 'POST',
            credentials: 'include',
            body: data
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                this.isUpdating = false;
                browserFilters.classList.remove('disabledElement');
                if (data.results.length == 0) {
                    if (isInfiniteScroll) {
                        this.endOfResults = true;
                        browseMessage.innerHTML = '<div style="width: 100%; text-align: center; margin-top: 50px; margin-bottom: 50px;">You\'ve reached the end of the results.</div>';
                    } else {
                        browseMessage.innerHTML = '<div style="width: 100%; text-align: center; margin-top: 50px; margin-bottom: 50px;"><img src="' + WATCHMODE_CDN1 + '/misc_images/icons/emojiFrown.png" style=" width: 95px; height: 95px;"><BR>Hmm, we couldn\'t seem to find any titles that matched your search.</div>';
                    }
                    return;
                }
                browseMessage.classList.remove('d-block');
                browseMessage.classList.add('d-none');
                var outputHTML = '';
                var r = 0;
                let bgClass = '';
                data.results.forEach((title) => {
                    if (r == 0 || r % 3 == 0) {
                        outputHTML += '<div class="row g-0">';
                    }
                    bgClass = !bgClass || bgClass == 'medGreyBg' ? 'medGreyBg2' : 'medGreyBg';
                    outputHTML += this.createPowerSearchResultDiv(title, bgClass);
                    r++;
                    if (r % 3 == 0) {
                        outputHTML += '</div>';
                    }
                });

                if (isInfiniteScroll) {
                    browseResults.innerHTML = browseResults.innerHTML + outputHTML;
                } else {
                    browseResults.innerHTML = outputHTML;
                }
            });
    }

    selectAllProviders() {
        var providerLogos = document.querySelectorAll('.provider_logo');
        providerLogos.forEach((providerLogo) => {
            providerLogo.classList.remove('provider_logo-unselected');
            providerLogo.classList.add('provider_logo-selected');
        });
        var servicesButtons = ['servicesAll', 'servicesMy', 'servicesRent', 'servicesBuy', 'servicesFree'];
        servicesButtons.forEach((servicesButton) => {
            var button = document.querySelector('#' + servicesButton);
            button.classList.add('btn-primary');
            button.classList.add('btn-secondary');
        });
    }

    handleScroll() {
        const footer = document.querySelector('.footer');
        const copyright = document.querySelector('.copyright');
        const bottomHeight = footer.offsetHeight + copyright.offsetHeight;
        const scrollPos = window.innerHeight + window.scrollY;
        // Infinite scroll fetch new titles
        if ((document.body.scrollHeight - bottomHeight >= scrollPos) / document.body.scrollHeight == 0) {
            if (!this.isUpdating && !this.endOfResults) {
                this.offset = this.rpp + this.offset;
                this.updateTitles(0, true);
            }
        }
        if (this.endOfResults && scrollPos < bottomHeight) {
            // Reset end of results flag if they scroll back to the top
            this.endOfResults = false;
        }
    }

    saveShareFormSubmitHandler(e) {
        e.preventDefault();
        const saveShareButton = document.getElementById('saveShareButton');
        saveShareButton.disabled = true;

        const providerLogos = document.querySelectorAll('.provider_logo-selected');
        var providerIDs = [];
        providerLogos.forEach((logo) => {
            if (logo.classList.contains('provider_logo-selected')) {
                providerIDs.push(logo.dataset.id);
            }
        });

        var genreButtons = document.querySelectorAll('.genreButtons');
        var genreIDs = [];
        genreButtons.forEach((button) => {
            if (button.classList.contains('btn-primary')) {
                genreIDs.push(button.dataset.id);
            }
        });

        var peopleIDs = [];
        this.peopleChoices.getValue().forEach((peopleToken) => {
            peopleIDs.push(peopleToken.value);
        });

        var tagsIds = [];
        this.tagsChoices.getValue().forEach((tagToken) => {
            tagsIds.push(tagToken.value);
        });

        var studioIds = [];
        this.studiosChoices.getValue().forEach((studioToken) => {
            studioIds.push(studioToken.value);
        });

        var userRatingOutputLow = document.getElementById('userScoreOutputLow');
        var userRatingOutputHigh = document.getElementById('userScoreOutputHigh');
        var userRatingLow = Math.round(parseFloat(userRatingOutputLow.innerHTML) * 10) / 10;
        var userRatingHigh = Math.round(parseFloat(userRatingOutputHigh.innerHTML) * 10) / 10;

        var criticScoreOutputLow = document.getElementById('criticScoreOutputLow');
        var criticScoreOutputHigh = document.getElementById('criticScoreOutputHigh');
        var criticScoreLow = parseInt(criticScoreOutputLow.innerHTML);
        var criticScoreHigh = parseInt(criticScoreOutputHigh.innerHTML);

        var yearOutputLow = document.getElementById('yearOutputLow');
        var yearOutputHigh = document.getElementById('yearOutputHigh');
        var yearLow = parseInt(yearOutputLow.innerHTML);
        var yearHigh = parseInt(yearOutputHigh.innerHTML);

        var languages = [];
        this.languageChoices.getValue().forEach((peopleToken) => {
            languages.push(peopleToken.value);
        });

        var myServicesSelected = document.getElementById('servicesMy').classList.contains('btn-primary') ? 1 : 0;
        var allServicesSelected = document.getElementById('servicesAll').classList.contains('btn-primary') ? 1 : 0;

        var titleType = '';
        const filterTypes = document.querySelectorAll('.filterType');
        filterTypes.forEach((filterType) => {
            if (filterType.classList.contains('btn-primary')) {
                titleType = filterType.dataset.type;
            }
        });

        var freeServicesSelected = document.getElementById('servicesFree').classList.contains('btn-primary') ? 1 : 0;
        var rentServicesSelected = document.getElementById('servicesRent').classList.contains('btn-primary') ? 1 : 0;
        var buyServicesSelected = document.getElementById('servicesBuy').classList.contains('btn-primary') ? 1 : 0;
        var hideNonEn = document.getElementById('hideNonEn').checked ? 1 : 0;
        var hideShort = document.getElementById('hideShort').checked ? 1 : 0;
        var hideSeen = document.getElementById('hideSeen').checked ? 1 : 0;
        var hideKids = document.getElementById('hideKids').checked ? 1 : 0;
        var hideMature = document.getElementById('hideMature').checked ? 1 : 0;
        var hideDocs = document.getElementById('hideDocs').checked ? 1 : 0;
        var hideAnimation = document.getElementById('hideAnimation').checked ? 1 : 0;
        var hideRatedNegative = document.getElementById('hideRatedNegative').checked ? 1 : 0;
        var hideFewerThanRatings = document.getElementById('hideFewerThanRatings').checked ? 1 : 0;
        var hideFewerThanRatingsCount = document.getElementById('hideFewerThanRatingsCount').value;

        var data = new FormData();
        data.set('appPlatform', 'web');
        providerIDs.forEach((providerID, i) => {
            data.set('providers[' + i + ']', providerID);
        });
        genreIDs.forEach((genreID, i) => {
            data.set('genres[' + i + ']', genreID);
        });
        languages.forEach((language, i) => {
            data.set('languages[' + i + ']', language);
        });
        peopleIDs.forEach((peopleID, i) => {
            data.set('people[' + i + ']', peopleID);
        });

        tagsIds.forEach((tag, i) => {
            data.set('tags[' + i + ']', tag);
        });

        studioIds.forEach((studio, i) => {
            data.set('productionCompanies[' + i + ']', studio);
        });

        data.set('myServicesSelected', myServicesSelected);
        data.set('allServicesSelected', allServicesSelected);
        data.set('yearLow', yearLow);
        data.set('yearHigh', yearHigh);
        data.set('userRatingLow', userRatingLow);
        data.set('userRatingHigh', userRatingHigh);
        data.set('criticScoreLow', criticScoreLow);
        data.set('criticScoreHigh', criticScoreHigh);
        data.set('rentServicesSelected', rentServicesSelected);
        data.set('buyServicesSelected', buyServicesSelected);
        data.set('freeServicesSelected', freeServicesSelected);
        data.set('hideNonEn', hideNonEn);
        data.set('hideShort', hideShort);
        data.set('hideSeen', hideSeen);
        data.set('hideKids', hideKids);
        data.set('hideMature', hideMature);
        data.set('hideAnimation', hideAnimation);
        data.set('hideRatedNegative', hideRatedNegative);
        data.set('hideFewerThanRatings', hideFewerThanRatings);
        data.set('hideFewerThanRatingsCount', hideFewerThanRatingsCount);
        data.set('hideDocs', hideDocs);
        data.set('titleType', titleType);
        data.set('sortBy', sortBy);
        data.set('resultsPerPage', this.rpp);
        data.set('offset', this.offset);
        const saveShareTitle = document.getElementById('saveShareTitle').value;
        data.set('saveShareTitle', saveShareTitle);
        data.set('makePublic', document.getElementById('makePublic').checked);
        data.set('showUsername', document.getElementById('showUsername').checked);

        fetch(GATEWAY_DOMAIN + '/saveSearchSettings/', {
            method: 'POST',
            credentials: 'include',
            body: data
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                const saveShareFormAlert = document.getElementById('saveShareFormAlert');
                saveShareButton.disabled = false;
                if (data.success) {
                    document.getElementById('saveShareForm').classList.add('d-none');

                    const makePublicAppend = data.makePublic
                        ? `<BR><BR>You can also share the link to this saved search:<div style="text-align: center; font-weight: bold; margin: 10px 0 5px 0; width: 90%;" id="savedSearchUrl">https://${WATCHMODE_CDN1}/browse/${data.savedShareSlug}/</div>
                    <div style="text-align: center; width: 100%">
                        <button class="js-copybtn" id="savedSearchUrlCopyButton">Copy Link</button>
                    </div>`
                        : '';
                    const successMessage = `Success! These search settings have been saved as&nbsp;
                    <div style="text-align: center; font-weight: bold; margin: 10px 0 10px 0;">${saveShareTitle}</div>You can access this by clicking "My Saved Searches".
                    ${makePublicAppend}`;

                    saveShareFormAlert.innerHTML = successMessage;
                    saveShareFormAlert.classList.remove('alert-danger', 'd-none');
                    saveShareFormAlert.classList.add('alert', 'alert-success', 'd-block');
                    const savedSearchTable = document.getElementById('savedSearchModalTableTbody');
                    savedSearchTable.innerHTML = savedSearchTable.innerHTML + '<tr><td><a href="/browse/' + data.slug + '/" class="teal" style="font-size: 0.8em; ">' + document.getElementById('saveShareTitle').value + '</a></td></tr>';
                } else {
                    saveShareFormAlert.innerHTML = data.errorMessage;
                    saveShareFormAlert.classList.remove('alert-success', 'd-none');
                    saveShareFormAlert.classList.add('alert', 'alert-danger', 'd-block');
                }
            });
    }

    checkGenresSelected() {
        // Are all the genres selected?
        var genresAllSelected = true;
        var genreCount = 0;
        var genreButtons = document.querySelectorAll('.genreButtons');
        const genresAll = document.getElementById('genresAll');
        genreButtons.forEach((button) => {
            if (button.id != 'genresAll' && button.classList.contains('btn-primary')) {
                genresAllSelected = false;
                genreCount++;
            }
        });

        const genreFilter = document.getElementById('genreFilter');
        if (genresAllSelected) {
            genreFilter.innerHTML = 'Genres: All';
            genreFilter.classList.add('btn-secondary');
            genreFilter.classList.remove('btn-primary');
        } else {
            genreFilter.innerHTML = 'Genres: ' + genreCount + ' selected';
            genreFilter.classList.remove('btn-secondary');
            genreFilter.classList.add('btn-primary');
            if (genresAll) {
                genresAll.classList.add('btn-secondary');
                genresAll.classList.remove('btn-primary');
            }
        }
        if (this.page == 'browse') {
            this.endOfResults = false;
            this.updateTitles(1, false);
        }
    }

    checkServicesButtons() {
        var foundServicesActive = 0;
        const servicesType = document.querySelectorAll('.servicesType');
        servicesType.forEach((servicesType) => {
            if (servicesType.classList.contains('btn-primary')) {
                foundServicesActive = 1;
            }
        });
        const providerLogosSelected = document.querySelectorAll('.provider_logo-selected');
        providerLogosSelected.forEach(() => {
            foundServicesActive = 1;
        });
        const servicesNext = document.getElementById('servicesNextDiv');
        if (this.page == 'watch-next' && servicesNext) {
            if (foundServicesActive == 1 || document.getElementById('servicesMy').classList.contains('btn-primary')) {
                servicesNext.classList.add('d-block');
                servicesNext.classList.remove('d-none');
            } else {
                servicesNext.classList.remove('d-block');
                servicesNext.classList.add('d-none');
            }
        }
    }

    clickHandler(event) {
        const genreButtons = document.querySelectorAll('.genreButtons');
        const providerLogos = document.querySelectorAll('.provider_logo');
        const filterTypes = document.querySelectorAll('.filterType');
        const serviceTypeButtons = document.querySelectorAll('.serviceTypes');
        const helperFunctions = require('../helperFunctions.js');
        if (event.target.matches('.genreButtons')) {
            event.preventDefault();
            if (event.target.classList.contains('btn-primary')) {
                event.target.classList.remove('btn-primary');
                event.target.classList.add('btn-secondary');
            } else {
                event.target.classList.add('btn-primary');
                event.target.classList.remove('btn-secondary');
            }
            if (this.page == 'watch-next') {
                const genresAll = document.getElementById('genresAll');
                if (event.target.id == 'genresAll') {
                    if (genresAll.classList.contains('btn-primary')) {
                        genreButtons.forEach((genreButton) => {
                            if (genreButton.id != 'genresAll') {
                                genreButton.classList.remove('btn-primary');
                                genreButton.classList.add('btn-secondary');
                            }
                        });
                    }
                } else {
                    genresAll.classList.remove('btn-primary');
                    genresAll.classList.add('btn-secondary');
                }
            }
            this.checkGenresSelected();
        }
        if (event.target.id == 'genresSelectAll') {
            event.preventDefault();
            genreButtons.forEach((button) => {
                button.classList.add('btn-primary');
                button.classList.remove('btn-secondary');
            });
            this.checkGenresSelected();
        }
        if (event.target.id == 'genresSelectNone') {
            event.preventDefault();
            genreButtons.forEach((button) => {
                button.classList.remove('btn-primary');
                button.classList.add('btn-secondary');
            });
            this.checkGenresSelected();
        }

        if (event.target.id == 'savedSearchUrlCopyButton') {
            var elm = document.getElementById('savedSearchUrl');
            var selection = window.getSelection();
            var range = document.createRange();
            range.selectNodeContents(elm);
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand('Copy');
            alert('Link copied to clipboard!');
        }

        // If the user unchecks "Make public", they shouldn't be able to click Show Username
        if (event.target.id == 'makePublic') {
            const showUsername = document.getElementById('showUsername');
            if (document.getElementById('makePublic').checked) {
                showUsername.disabled = false;
            } else {
                showUsername.checked = false;
                showUsername.disabled = true;
            }
        }

        if (this.page == 'watchlistFilter') {
            if (event.target.id == 'servicesAll') {
                event.preventDefault();
                providerLogos.forEach((logo) => {
                    logo.classList.add('provider_logo-selected');
                    logo.classList.remove('provider_logo-unselected');
                    var providerContainer = document.getElementById('providerContainer' + logo.id);
                    providerContainer.classList.add('provider-container-selected');
                });
                serviceTypeButtons.forEach((button) => {
                    if (button.id != 'servicesNone') {
                        button.classList.add('btn-primary');
                        button.classList.remove('btn-secondary');
                    }
                });
            } else if (event.target.id == 'servicesNone') {
                event.preventDefault();
                providerLogos.forEach((logo) => {
                    logo.classList.remove('provider_logo-selected');
                    logo.classList.add('provider_logo-unselected');
                    var providerContainer = document.getElementById('providerContainer' + logo.id);
                    providerContainer.classList.remove('provider-container-selected');
                });
                serviceTypeButtons.forEach((button) => {
                    button.classList.remove('btn-primary');
                    button.classList.add('btn-secondary');
                });
            }
        }

        // Update the titles when any of the hide check boxes is checked
        if (event.target.matches('.hideFromResults')) {
            var foundHidden = 0;
            const hideFromResults = document.querySelectorAll('.hideFromResults');
            hideFromResults.forEach((hideFrom) => {
                if (hideFrom.checked) {
                    foundHidden++;
                }
            });
            const hiddenFilter = document.getElementById('hiddenFilter');
            if (foundHidden > 0) {
                hiddenFilter.innerHTML = 'Hidden: ' + foundHidden + ' type' + (foundHidden > 1 ? 's' : '');
                hiddenFilter.classList.add('btn-primary');
                hiddenFilter.classList.remove('btn-secondary');
            } else {
                hiddenFilter.innerHTML = 'Hidden: None';
                hiddenFilter.classList.add('btn-secondary');
                hiddenFilter.classList.remove('btn-primary');
            }
        }

        if (event.target.id === 'hideFromResultsDoneButton') {
            if (this.page == 'browse') {
                this.endOfResults = false;
                this.updateTitles(1, false);
            }
        }

        // Change the sort by variable and refresh titles when the sortBy pulldown is changed
        if (event.target.matches('.powerSearchSortButton')) {
            event.preventDefault();
            var sortLabel = event.target.dataset.label;
            document.getElementById('sortFilter').innerHTML = 'Sort: ' + sortLabel;
            sortBy = event.target.dataset.value;
            if (this.page == 'browse') {
                this.endOfResults = false;
                this.updateTitles(1, false);
            }
        }

        // If a filter button is pressed, set that button to primary color class, and refresh the titles (if not on index)
        if (event.target.matches('.filterType')) {
            event.preventDefault();
            filterTypes.forEach((filterType) => {
                filterType.classList.remove('btn-primary');
                filterType.classList.add('btn-secondary');
            });
            event.target.classList.add('btn-primary');
            event.target.classList.remove('btn-secondary');
            if (this.page == 'browse') {
                this.updateTitles(1, false);
            }
            if (this.page == 'browse') {
                this.endOfResults = false;
            }
        }

        // If a provider logo is pressed, toggle it's highlight state
        if (event.target.matches('.provider_logo')) {
            event.preventDefault();
            if (event.target.classList.contains('.disabledLink') || event.target.closest('.disabledLink')) {
                return;
            }
            const providerContainer = document.getElementById('providerContainer' + event.target.id);
            if (event.target.classList.contains('provider_logo-selected')) {
                providerContainer.classList.remove('provider-container-selected');
                event.target.classList.remove('provider_logo-selected');
                event.target.classList.add('provider_logo-unselected');
            } else {
                providerContainer.classList.add('provider-container-selected');
                event.target.classList.add('provider_logo-selected');
                event.target.classList.remove('provider_logo-unselected');
            }
            this.endOfResults = false;
            if (this.page == 'browse') {
                this.updateTitles(1, false);
            } else if (this.page == 'watch-next') {
                const myServicesButton = document.getElementById('servicesMy');
                myServicesButton.classList.remove('btn-primary');
                myServicesButton.classList.add('btn-secondary');
                this.checkServicesButtons();
            }
        }

        if (event.target.matches('.serviceTypes')) {
            event.preventDefault();
            if (event.target.classList.contains('btn-primary')) {
                // Toggle button on
                if (event.target.id == 'servicesMy') {
                    if (myProviders.length == 0) {
                        helperFunctions.showModal('needAccountModal');
                        return false;
                    }
                    for (let index = 0; index < myProviders.length; ++index) {
                        var providerIcon = document.getElementById(myProviders[index]);
                        if (!providerIcon) {
                            continue;
                        }
                        var providerContainer = document.getElementById('providerContainer' + myProviders[index]);
                        providerIcon.classList.add('provider_logo-unselected');
                        providerIcon.classList.remove('provider_logo-selected');
                        providerContainer.classList.remove('provider-container-selected');
                    }
                }
                if (event.target.id != 'servicesAll' && this.page != 'watchlistFilter') {
                    const servicesAll = document.getElementById('servicesAll');
                    servicesAll.classList.add('btn-secondary');
                    servicesAll.classList.remove('btn-primary');
                }
                event.target.classList.remove('btn-primary');
                event.target.classList.add('btn-secondary');
            } else {
                // Toggle button on - Change states accordingly depending on what button was pressed
                if (event.target.id == 'servicesAll') {
                    providerLogos.forEach((logo) => {
                        logo.classList.add('provider_logo-selected');
                        logo.classList.remove('provider_logo-unselected');
                        var providerContainer = document.getElementById('providerContainer' + logo.id);
                        providerContainer.classList.add('provider-container-selected');
                    });
                    serviceTypeButtons.forEach((button) => {
                        if (button.id != 'servicesNone') {
                            button.classList.add('btn-primary');
                            button.classList.remove('btn-secondary');
                        }
                    });
                } else if (event.target.id == 'servicesNone') {
                    providerLogos.forEach((logo) => {
                        logo.classList.remove('provider_logo-selected');
                        logo.classList.add('provider_logo-unselected');
                        var providerContainer = document.getElementById('providerContainer' + logo.id);
                        providerContainer.classList.remove('provider-container-selected');
                    });
                    serviceTypeButtons.forEach((button) => {
                        button.classList.remove('btn-primary');
                        button.classList.add('btn-secondary');
                    });
                } else if (event.target.id == 'servicesMy') {
                    if (myProviders.length == 0) {
                        helperFunctions.showModal('needAccountModal');
                        return false;
                    }
                    for (let index = 0; index < myProviders.length; ++index) {
                        var providerIcon2 = document.getElementById(myProviders[index]);
                        if (!providerIcon2) {
                            continue;
                        }
                        var providerContainer2 = document.getElementById('providerContainer' + myProviders[index]);
                        providerIcon2.classList.remove('provider_logo-unselected');
                        providerIcon2.classList.add('provider_logo-selected');
                        providerContainer2.classList.add('provider-container-selected');
                    }
                }
                if (event.target.id != 'servicesNone') {
                    event.target.classList.add('btn-primary');
                    event.target.classList.remove('btn-secondary');
                }
            }
            if (this.page == 'browse') {
                this.endOfResults = false;
                this.updateTitles(1, false);
            }
        }
    }

    updateLanguageLabel() {
        const languagesFilter = document.getElementById('languagesFilter');
        var languages = [];
        this.languageChoices.getValue().forEach((peopleToken) => {
            languages.push(peopleToken.value);
        });
        if (languages.length == 0) {
            languagesFilter.classList.remove('btn-primary');
            languagesFilter.classList.add('btn-secondary');
            languagesFilter.innerHTML = 'Languages: All';
        } else {
            languagesFilter.classList.add('btn-primary');
            languagesFilter.classList.remove('btn-secondary');
            languagesFilter.innerHTML = 'Languages: ' + languages.length + ' selected';
        }
    }

    updatePeopleOutput() {
        const peopleFilter = document.getElementById('peopleFilter');
        var people = [];
        this.peopleChoices.getValue().forEach((peopleToken) => {
            people.push(peopleToken.value);
        });
        if (people.length == 0) {
            peopleFilter.classList.remove('btn-primary');
            peopleFilter.classList.add('btn-secondary');
            peopleFilter.innerHTML = 'People: All';
        } else {
            peopleFilter.classList.add('btn-primary');
            peopleFilter.classList.remove('btn-secondary');
            peopleFilter.innerHTML = 'People: ' + people.length + ' selected';
        }
        if (this.page == 'browse') {
            this.endOfResults = false;
        }
        this.peopleChoices.clearChoices();
    }

    updateStudiosOutput() {
        const studiosFilter = document.getElementById('studiosFilter');
        var studios = [];
        this.studiosChoices.getValue().forEach((studioToken) => {
            studios.push(studioToken.value);
        });
        if (studios.length == 0) {
            studiosFilter.classList.remove('btn-primary');
            studiosFilter.classList.add('btn-secondary');
            studiosFilter.innerHTML = 'Studios: All';
        } else {
            studiosFilter.classList.add('btn-primary');
            studiosFilter.classList.remove('btn-secondary');
            studiosFilter.innerHTML = 'Studios: ' + studios.length + ' selected';
        }
        if (this.page == 'browse') {
            this.endOfResults = false;
        }
    }

    updateTagsOutput() {
        const tagsFilter = document.getElementById('tagsFilter');
        var tags = [];
        this.tagsChoices.getValue().forEach((tagToken) => {
            tags.push(tagToken.value);
        });
        if (tags.length == 0) {
            tagsFilter.classList.remove('btn-primary');
            tagsFilter.classList.add('btn-secondary');
            tagsFilter.innerHTML = 'Tags: All';
        } else {
            tagsFilter.classList.add('btn-primary');
            tagsFilter.classList.remove('btn-secondary');
            tagsFilter.innerHTML = 'Tags: ' + tags.length + ' selected';
        }
        if (this.page == 'browse') {
            this.endOfResults = false;
        }
    }

    powerBrowseSubmit() {
        // Collect the user's power search selected parameters, and pass them to /browse/ to execute the search
        var parameters = {};
        const genreButtons = document.querySelectorAll('.genreButtons');
        const providerLogos = document.querySelectorAll('.provider_logo');
        const filterTypes = document.querySelectorAll('.filterType');
        filterTypes.forEach((filterType) => {
            if (filterType.classList.contains('btn-primary')) {
                parameters.titleType = filterType.dataset.type;
            }
        });
        var providerIds = [];
        providerLogos.forEach((logo) => {
            if (logo.classList.contains('provider_logo-selected')) {
                if (logo.id != 'myProviders') {
                    providerIds.push(parseInt(logo.id, 10));
                } else {
                    myProviders.forEach((element) => {
                        if (!providerIds.includes(element)) {
                            providerIds.push(element);
                        }
                    });
                }
            }
        });

        var genreIDs = [];
        genreButtons.forEach((button) => {
            if (button.classList.contains('btn-primary')) {
                genreIDs.push(button.dataset.id);
            }
        });
        var peopleIDs = [];
        this.peopleChoices.getValue().forEach((peopleToken) => {
            peopleIDs.push(peopleToken.value);
        });

        var languages = [];
        this.languageChoices.getValue().forEach((peopleToken) => {
            languages.push(peopleToken.value);
        });

        var tags = [];
        this.tagsChoices.getValue().forEach((tagToken) => {
            tags.push(tagToken.value);
        });

        var studios = [];
        this.studiosChoices.getValue().forEach((studioToken) => {
            studios.push(studioToken.value);
        });

        var userRatingOutputLow = document.getElementById('userScoreOutputLow');
        var userRatingOutputHigh = document.getElementById('userScoreOutputHigh');
        var userRatingLow = Math.round(parseFloat(userRatingOutputLow.innerHTML) * 10) / 10;
        var userRatingHigh = Math.round(parseFloat(userRatingOutputHigh.innerHTML) * 10) / 10;

        var criticScoreOutputLow = document.getElementById('criticScoreOutputLow');
        var criticScoreOutputHigh = document.getElementById('criticScoreOutputHigh');
        var criticScoreLow = parseInt(criticScoreOutputLow.innerHTML);
        var criticScoreHigh = parseInt(criticScoreOutputHigh.innerHTML);

        var yearOutputLow = document.getElementById('yearOutputLow');
        var yearOutputHigh = document.getElementById('yearOutputHigh');
        var yearLow = parseInt(yearOutputLow.innerHTML);
        var yearHigh = parseInt(yearOutputHigh.innerHTML);

        parameters.providers = providerIds;
        parameters.allServicesSelected = document.getElementById('servicesAll').classList.contains('btn-primary') ? 1 : 0;
        parameters.showMy = document.getElementById('servicesMy').classList.contains('btn-primary') ? 1 : 0;
        parameters.genres = genreIDs;
        parameters.sortBy = sortBy;
        parameters.people = peopleIDs;
        parameters.yearLow = yearLow;
        parameters.yearHigh = yearHigh;
        parameters.languages = languages;
        parameters.tags = tags;
        parameters.studios = studios;
        parameters.userRatingLow = userRatingLow;
        parameters.userRatingHigh = userRatingHigh;
        parameters.criticScoreLow = criticScoreLow;
        parameters.criticScoreHigh = criticScoreHigh;
        parameters.hideDocs = document.getElementById('hideDocs').checked ? 1 : 0;
        parameters.hideKids = document.getElementById('hideKids').checked ? 1 : 0;
        parameters.hideMature = document.getElementById('hideMature').checked ? 1 : 0;
        parameters.hideSeen = document.getElementById('hideSeen').checked ? 1 : 0;
        parameters.hideShort = document.getElementById('hideShort').checked ? 1 : 0;
        parameters.hideNonEn = document.getElementById('hideNonEn').checked ? 1 : 0;
        parameters.hideAnimation = document.getElementById('hideAnimation').checked ? 1 : 0;
        parameters.hideRatedNegative = document.getElementById('hideRatedNegative').checked ? 1 : 0;
        parameters.hideFewerThanRatings = document.getElementById('hideFewerThanRatings').checked ? 1 : 0;
        parameters.hideFewerThanRatingsCount = document.getElementById('hideFewerThanRatingsCount').value;
        parameters.rentServicesSelected = document.getElementById('servicesRent').classList.contains('btn-primary') ? 1 : 0;
        parameters.buyServicesSelected = document.getElementById('servicesBuy').classList.contains('btn-primary') ? 1 : 0;
        parameters.freeServicesSelected = document.getElementById('servicesFree').classList.contains('btn-primary') ? 1 : 0;
        window.location.href = '/browse/?parameters=' + encodeURIComponent(JSON.stringify(parameters));
    }
}
