/* global WATCHMODE_CDN1, isLoggedIn */
// eslint-disable-next-line no-unused-vars
import { showToast, showModal, hideModal, monitorCarouselDrags } from '../helperFunctions.js';
import { Modal } from 'bootstrap/js/dist/modal';
import { GATEWAY_DOMAIN } from '../constants.js';

export class Dashboard {
    constructor(dashboardSessionId = '') {
        this.ratingCombinedTitleID = '';
        this.listManagerCombinedTitleID = '';
        this.carouselFetchQueue = [];
        this.clickedCarouselID = '';
        this.dashboardSessionId = dashboardSessionId;
        this.ratingSource = 0;
        this.timeout = null;
        this.imageObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const image = entry.target;
                    image.src = image.dataset.src;
                    image.classList.remove('lazy');
                    image.classList.remove('lazyNotViewable');
                    image.classList.add('lazyViewable');
                    this.imageObserver.unobserve(image);
                }
            });
        });
    }

    clickHandler(event) {
        if (event.target.id == 'dashboardServicesSave' || event.target.id == 'dashboardHideSave') {
            this.reloadDashboard();
        }

        if (event.target.id == 'dashboardCollectionsSave') {
            this.handleDashboardCollectionsSave();
        }

        // Show a modal when a non logged in user clicks My Services
        if (!isLoggedIn && event.target.id == 'servicesMy') {
            showModal('needAccountModal');
            const servicesMy = document.getElementById('servicesMy');
            servicesMy.checked = false;
        }
        // Spawn the list manager modal, to control list/myshow status
        if (event.target.closest('.lml') || event.target.classList.contains('lml')) {
            event.preventDefault();
            if (isLoggedIn) {
                const parentTileContainer = event.target.closest('.pts');
                if (parentTileContainer && parentTileContainer.dataset.ctid) {
                    const posterListManagerTitleEl = document.getElementById('posterListManagerModalLabel');
                    posterListManagerTitleEl.innerHTML = parentTileContainer.dataset.title;
                    this.listManagerCombinedTitleID = parentTileContainer.dataset.ctid;

                    const posterListManagerToggleWatchlistButton = document.getElementById('posterListManagerToggleWatchlist');
                    if (parentTileContainer.dataset.watchliststatus == 1) {
                        posterListManagerToggleWatchlistButton.classList.add('btn-primary');
                        posterListManagerToggleWatchlistButton.classList.remove('btn-secondary');
                        posterListManagerToggleWatchlistButton.innerHTML = 'Remove from My Watchlist';
                    } else {
                        posterListManagerToggleWatchlistButton.classList.remove('btn-primary');
                        posterListManagerToggleWatchlistButton.classList.add('btn-secondary');
                        posterListManagerToggleWatchlistButton.innerHTML = 'Add to My Watchlist';
                    }

                    const posterListManagerToggleMyShowsButton = document.getElementById('posterListManagerToggleMyShows');
                    if (parentTileContainer.dataset.myshowsstatus == 1) {
                        posterListManagerToggleMyShowsButton.classList.add('btn-primary');
                        posterListManagerToggleMyShowsButton.classList.remove('btn-secondary');
                        posterListManagerToggleMyShowsButton.innerHTML = 'Remove from My Shows';
                    } else {
                        posterListManagerToggleMyShowsButton.classList.remove('btn-primary');
                        posterListManagerToggleMyShowsButton.classList.add('btn-secondary');
                        posterListManagerToggleMyShowsButton.innerHTML = 'Add to My Shows';
                    }

                    if (parentTileContainer.dataset.titletype != 'tv_series' && parentTileContainer.dataset.titletype != 'tv_miniseries') {
                        posterListManagerToggleMyShowsButton.style.display = 'none';
                    } else {
                        posterListManagerToggleMyShowsButton.style.display = 'inline-block';
                    }

                    const inCustomLists = JSON.parse(parentTileContainer.dataset.incustomlists);
                    const customListButtons = document.querySelectorAll('.posterListManagerCustomList');
                    customListButtons.forEach((customListButton) => {
                        if (inCustomLists.includes(parseInt(customListButton.dataset.listid))) {
                            customListButton.classList.add('btn-primary');
                            customListButton.classList.remove('btn-secondary');
                        } else {
                            customListButton.classList.remove('btn-primary');
                            customListButton.classList.add('btn-secondary');
                        }
                    });

                    const posterListManagerCreateList = document.getElementById('posterListManagerCreateList');
                    posterListManagerCreateList.dataset.combinedtitleid = this.listManagerCombinedTitleID;
                    posterListManagerCreateList.dataset.titlename = parentTileContainer.dataset.title;

                    showModal('posterListManagerModal');
                }
            } else {
                showModal('needAccountModal');
            }
        }

        if (event.target.classList.contains('posterListManagerCustomList')) {
            event.preventDefault();
            event.target.disabled = true;
            const data = new FormData();
            data.set('appPlatform', 'web');
            data.set('combinedTitleID', this.listManagerCombinedTitleID);
            data.set('method', 'listAddRemove');
            data.set('listID', event.target.dataset.listid);
            data.set('type', event.target.classList.contains('btn-primary') ? 'remove' : 'add');
            fetch(GATEWAY_DOMAIN + '/userActions/', {
                method: 'POST',
                credentials: 'include',
                body: data
            })
                .then((response) => {
                    return response.json();
                })
                .then(() => {
                    event.target.disabled = false;
                    // Get all poster containers and update the incustomlists data element
                    const posterTiles = document.querySelectorAll('.pts');
                    posterTiles.forEach((posterTile) => {
                        if (posterTile.dataset.ctid == this.listManagerCombinedTitleID) {
                            var inCustomLists = JSON.parse(posterTile.dataset.incustomlists);
                            if (event.target.classList.contains('btn-primary')) {
                                inCustomLists = inCustomLists.filter((value) => {
                                    return value != event.target.dataset.listid;
                                });
                            } else {
                                inCustomLists.push(parseInt(event.target.dataset.listid));
                            }
                            posterTile.dataset.incustomlists = JSON.stringify(inCustomLists);
                        }
                    });
                    this.setListManagerButtonIcon(this.listManagerCombinedTitleID);
                    if (event.target.classList.contains('btn-primary')) {
                        event.target.classList.remove('btn-primary');
                        event.target.classList.add('btn-secondary');
                        showToast('Removed from list', 'bottomCenter');
                    } else {
                        event.target.classList.add('btn-primary');
                        event.target.classList.remove('btn-secondary');
                        showToast('Added to list', 'bottomCenter');
                    }
                });
        }

        // Handle the toggling of a title's watchlist status from the posterListManagerModal
        if (event.target.id == 'posterListManagerToggleWatchlist') {
            event.preventDefault();
            const posterListManagerToggleWatchlistButton = document.getElementById('posterListManagerToggleWatchlist');
            posterListManagerToggleWatchlistButton.disabled = true;
            const watchlistStatus = posterListManagerToggleWatchlistButton.classList.contains('btn-primary') ? 0 : 1;
            const data = new FormData();
            data.set('appPlatform', 'web');
            data.set('combinedTitleID', this.listManagerCombinedTitleID);
            data.set('method', 'watchlistStatus');
            data.set('watchlistStatus', watchlistStatus);
            fetch(GATEWAY_DOMAIN + '/userActions/', {
                method: 'POST',
                credentials: 'include',
                body: data
            })
                .then((response) => {
                    return response.json();
                })
                .then(() => {
                    posterListManagerToggleWatchlistButton.disabled = false;
                    // Get all poster tile containers and update the watchlistatus data element
                    const posterTiles = document.querySelectorAll('.pts');
                    posterTiles.forEach((posterTile) => {
                        if (posterTile.dataset.ctid == this.listManagerCombinedTitleID) {
                            posterTile.dataset.watchliststatus = watchlistStatus;
                        }
                    });
                    this.setListManagerButtonIcon(this.listManagerCombinedTitleID);
                    if (watchlistStatus == 0) {
                        posterListManagerToggleWatchlistButton.classList.remove('btn-primary');
                        posterListManagerToggleWatchlistButton.classList.add('btn-secondary');
                        posterListManagerToggleWatchlistButton.innerHTML = 'Add to My Watchlist';
                        showToast('Removed from Watchlist', 'bottomCenter');
                    } else {
                        posterListManagerToggleWatchlistButton.classList.add('btn-primary');
                        posterListManagerToggleWatchlistButton.classList.remove('btn-secondary');
                        posterListManagerToggleWatchlistButton.innerHTML = 'Remove from My Watchlist';
                        showToast('Added to Watchlist', 'bottomCenter');
                    }
                });
        }

        // Handle the toggling of a title's myShows status from the posterListManagerModal
        if (event.target.id == 'posterListManagerToggleMyShows') {
            event.preventDefault();
            const posterListManagerToggleMyShowsButton = document.getElementById('posterListManagerToggleMyShows');
            posterListManagerToggleMyShowsButton.disabled = true;
            const myShowsStatus = posterListManagerToggleMyShowsButton.classList.contains('btn-primary') ? 0 : 1;
            const data = new FormData();
            data.set('appPlatform', 'web');
            data.set('combinedTitleID', this.listManagerCombinedTitleID);
            data.set('method', 'myShowsToggle');
            fetch(GATEWAY_DOMAIN + '/userActions/', {
                method: 'POST',
                credentials: 'include',
                body: data
            })
                .then((response) => {
                    return response.json();
                })
                .then(() => {
                    posterListManagerToggleMyShowsButton.disabled = false;
                    // Get all poster tile containers and update the myshowsstatus data element
                    const posterTiles = document.querySelectorAll('.pts');
                    posterTiles.forEach((posterTile) => {
                        if (posterTile.dataset.ctid == this.listManagerCombinedTitleID) {
                            posterTile.dataset.myshowsstatus = myShowsStatus;
                        }
                    });
                    this.setListManagerButtonIcon(this.listManagerCombinedTitleID);
                    if (myShowsStatus == 0) {
                        posterListManagerToggleMyShowsButton.classList.remove('btn-primary');
                        posterListManagerToggleMyShowsButton.classList.add('btn-secondary');
                        posterListManagerToggleMyShowsButton.innerHTML = 'Add to My Shows';
                        showToast('Removed from My Shows', 'bottomCenter');
                    } else {
                        posterListManagerToggleMyShowsButton.classList.add('btn-primary');
                        posterListManagerToggleMyShowsButton.classList.remove('btn-secondary');
                        posterListManagerToggleMyShowsButton.innerHTML = 'Remove from My Shows';
                        showToast('Added to My Shows', 'bottomCenter');
                    }
                });
        }

        // when a user presses the status icon over the poster, show a modal to allow them to change their status
        if (event.target.closest('.tileStatusButton')) {
            event.preventDefault();
            const parentTileContainer = event.target.closest('.pts');
            this.ratingSource = 1;
            if (isLoggedIn) {
                this.ratingCombinedTitleID = parentTileContainer.dataset.ctid;
                this.clickedCarouselID = parentTileContainer.dataset.cid;
                document.getElementById('ratingsModalTitle').innerHTML = parentTileContainer.dataset.title;

                // Change the content of the modal depending on if the title has been released
                if (parentTileContainer.dataset.isreleasedyet == 1) {
                    document.getElementById('ratingsModalLabel').classList.add('d-block');
                    document.getElementById('ratingsModalLabel').classList.remove('d-none');
                    document.getElementById('haventSeenLabel').classList.remove('d-none');
                    document.getElementById('haventSeenLabel').classList.add('d-block');
                    document.getElementById('modalRatingsChoose').classList.remove('d-none');
                    document.getElementById('modalRatingsChoose').classList.add('d-block');
                    document.getElementById('ratingsModalNotReleased').classList.remove('d-block');
                    document.getElementById('ratingsModalNotReleased').classList.add('d-none');
                } else {
                    document.getElementById('ratingsModalLabel').classList.remove('d-block');
                    document.getElementById('ratingsModalLabel').classList.add('d-none');
                    document.getElementById('haventSeenLabel').classList.remove('d-block');
                    document.getElementById('haventSeenLabel').classList.add('d-none');
                    document.getElementById('modalRatingsChoose').classList.add('d-none');
                    document.getElementById('modalRatingsChoose').classList.remove('d-block');
                    document.getElementById('ratingsModalNotReleased').classList.remove('d-none');
                    document.getElementById('ratingsModalNotReleased').classList.add('d-block');
                }
                showModal('ratingsModal');
            } else {
                showModal('needAccountModal');
            }
        }

        // When a user presses the red X over a recommendation, show a modal to allow them to tell us why
        if (event.target.matches('.tileRecommendationRemoveButton')) {
            event.preventDefault();
            this.ratingSource = 2;
            if (isLoggedIn) {
                showModal('removeRecommendationModal');
                document.getElementById('seenRatingModalTitle').innerHTML = event.target.dataset.title;
                this.ratingCombinedTitleID = event.target.dataset.combinedtitleid;
                this.clickedCarouselID = event.target.dataset.carouselid;
            } else {
                showModal('needAccountModal');
            }
        }

        if (event.target.matches('.dashboardOptionButton')) {
            if ((event.target.id == 'dashboardHideSeen' || event.target.id == 'dashboardServicesMy' || event.target.id == 'dashboardServicesDontHave' || event.target.id == 'dashboardHideRatedNegative') && !isLoggedIn) {
                showModal('needAccountModal');
            } else {
                if (event.target.classList.contains('btn-primary')) {
                    event.target.classList.add('btn-secondary');
                    event.target.classList.remove('btn-primary');
                } else {
                    event.target.classList.remove('btn-secondary');
                    event.target.classList.add('btn-primary');
                }
                // De-select all others when the all buttons is pressed
                if (event.target.id == 'dashboardServicesAll') {
                    var dashboardOptionsButtons = document.querySelectorAll('.dashboardOptionButton');
                    dashboardOptionsButtons.forEach((button) => {
                        if (button.id != 'dashboardServicesAll') {
                            button.classList.add('btn-secondary');
                            button.classList.remove('btn-primary');
                        }
                    });
                }
            }
        }

        // Dismiss a featured item and send the ID to the server to save in the users dismissed items
        if (event.target.closest('.featuredItemDismiss')) {
            event.preventDefault();
            const itemID = event.target.closest('.featuredItemDismiss').dataset.itemid;

            var data = new FormData();
            data.set('appPlatform', 'web');
            data.set('method', 'dismissFeaturedItem');
            data.set('itemID', itemID);

            fetch(GATEWAY_DOMAIN + '/userActions/', {
                method: 'POST',
                credentials: 'include',
                body: data
            }).then((response) => {
                return response.json();
            });

            // Find the featured item with this itemID and remove it
            var featuredItems = document.querySelectorAll('.featuredItemContainer');
            featuredItems.forEach((item) => {
                if (item.dataset.itemid == itemID) {
                    item.remove();
                }
            });
        }

        // When the user has pressed a ratings button in the recommendation feedback modal and rated a title, send the feedback to the server
        if (event.target.matches('.feedbackButton')) {
            if (event.target.classList.contains('feedbackActive')) {
                event.target.classList.remove('feedbackActive');
            } else {
                event.target.classList.add('feedbackActive');
            }

            const feedbackButtons = document.querySelectorAll('.feedbackButton');
            feedbackButtons.forEach((button) => {
                if (button.id != event.target.id) {
                    button.classList.remove('feedbackActive');
                }
            });
            var interested = '';
            if (document.getElementById('feedback1').classList.contains('feedbackActive')) {
                interested = 'statushsinterested';
            } else if (document.getElementById('feedback2').classList.contains('feedbackActive')) {
                interested = 'statushsdontthinkso';
            } else if (document.getElementById('feedback3').classList.contains('feedbackActive')) {
                interested = 'statushsno';
            } else {
                interested = '';
            }
            const feedbackData = new FormData();
            feedbackData.set('appPlatform', 'web');
            feedbackData.set('combinedTitleID', this.ratingCombinedTitleID);
            feedbackData.set('method', 'recommendationFeedback');
            feedbackData.set('status', interested);

            fetch(GATEWAY_DOMAIN + '/userActions/', {
                method: 'POST',
                credentials: 'include',
                body: feedbackData
            })
                .then((response) => {
                    return response.json();
                })
                .then(() => {
                    const removeRecommendationModalEl = document.getElementById('removeRecommendationModal');
                    const removeRecommendationModal = Modal.getInstance(removeRecommendationModalEl);
                    removeRecommendationModal.hide();
                    const posterTiles = document.querySelectorAll('.pts');
                    posterTiles.forEach((posterTile) => {
                        if (posterTile.dataset.ctid == this.ratingCombinedTitleID && posterTile.dataset.cid == this.clickedCarouselID) {
                            posterTile.classList.add('fadeOutMedium');
                            this.timeout = setTimeout(() => {
                                posterTile.remove();
                            }, 1000);
                            feedbackButtons.forEach((button) => {
                                button.classList.remove('feedbackActive');
                            });
                        }
                    });
                });
        }

        // When the user has pressed a "havent seen" button in the recommendation feedback modal, send the feedback to the server
        if (event.target.matches('.haventSeen')) {
            const interest = event.target.dataset.interest;
            let status = '';
            let bgClass = '';
            let svgIcon = '';
            if (interest == 1) {
                status = 'statushsinterested';
                bgClass = 'tileStatusButtonBgLightGreen';
                svgIcon = 'hand-thumbs-up';
            } else if (interest == 2) {
                status = 'statushsdontthinkso';
                bgClass = 'tileStatusButtonBgOrange';
                svgIcon = 'emoji-expressionless';
            } else if (interest == 3) {
                status = 'statushsno';
                bgClass = 'tileStatusButtonBgRed';
                svgIcon = 'hand-thumbs-down';
            }

            const tileStatusButtons = document.querySelectorAll('.tileStatusButton');
            tileStatusButtons.forEach((tileStatusButton) => {
                if (tileStatusButton.dataset.ctid == this.ratingCombinedTitleID) {
                    tileStatusButton.classList.remove('tileStatusButtonBgBlack', 'tileStatusButtonBgGold', 'tileStatusButtonBgDarkGreen', 'tileStatusButtonBgLightGreen', 'tileStatusButtonBgOrange', 'tileStatusButtonBgBrown', 'tileStatusButtonBgRed');
                    tileStatusButton.classList.add(bgClass);
                    tileStatusButton.innerHTML = '<img src="' + WATCHMODE_CDN1 + '/icons/' + svgIcon + '.svg" alt="" width="24" height="24">';
                }
            });
            const feedbackData = new FormData();
            feedbackData.set('appPlatform', 'web');
            feedbackData.set('combinedTitleID', this.ratingCombinedTitleID);
            feedbackData.set('method', 'titleStatus');
            feedbackData.set('status', status);

            fetch(GATEWAY_DOMAIN + '/userActions/', {
                method: 'POST',
                credentials: 'include',
                body: feedbackData
            })
                .then((response) => {
                    return response.json();
                })
                .then(() => {
                    showToast('Status updated!', 'bottomCenter');
                });
        }

        // When a user set's a rating for a title, send the rating to the server, and change the rating icon to the appropriate image
        if (event.target.matches('.btn-rating')) {
            const rating = event.target.dataset.rating;
            let status = '';
            let bgClass = '';
            let svgIcon = '';
            if (rating == 10) {
                status = 'statusfavorite';
                bgClass = 'tileStatusButtonBgGold';
                svgIcon = 'trophy';
            } else if (rating == 8) {
                status = 'statuslovedit';
                bgClass = 'tileStatusButtonBgDarkGreen';
                svgIcon = 'heart';
            } else if (rating == 7) {
                status = 'statuslikedit';
                bgClass = 'tileStatusButtonBgLightGreen';
                svgIcon = 'hand-thumbs-up';
            } else if (rating == 5) {
                status = 'statusok';
                bgClass = 'tileStatusButtonBgOrange';
                svgIcon = 'emoji-expressionless';
            } else if (rating == 4) {
                status = 'statusdidntlike';
                bgClass = 'tileStatusButtonBgBrown';
                svgIcon = 'emoji-frown';
            } else if (rating == 2) {
                status = 'statushate';
                bgClass = 'tileStatusButtonBgRed';
                svgIcon = 'hand-thumbs-down';
            }
            if (this.ratingSource == 2) {
                // Remove the poster if this is a recommendation rating
                const posterTiles = document.querySelectorAll('.pts');
                posterTiles.forEach((posterTile) => {
                    if (posterTile.dataset.ctid == this.ratingCombinedTitleID && (posterTile.dataset.cid == 'recommendedMovies' || posterTile.dataset.cid == 'recommendedTV')) {
                        posterTile.classList.add('fadeOutMedium');
                        this.timeout = setTimeout(() => {
                            posterTile.remove();
                        }, 1000);
                    }
                });
                hideModal('seenRatingModal');
            }

            const tileStatusButtons = document.querySelectorAll('.tileStatusButton');
            tileStatusButtons.forEach((tileStatusButton) => {
                if (tileStatusButton.dataset.ctid == this.ratingCombinedTitleID) {
                    tileStatusButton.classList.remove('tileStatusButtonBgBlack', 'tileStatusButtonBgGold', 'tileStatusButtonBgDarkGreen', 'tileStatusButtonBgLightGreen', 'tileStatusButtonBgOrange', 'tileStatusButtonBgBrown', 'tileStatusButtonBgRed');
                    tileStatusButton.classList.add(bgClass);
                    tileStatusButton.innerHTML = '<img src="' + WATCHMODE_CDN1 + '/icons/' + svgIcon + '.svg" alt="" width="24" height="24">';
                }
            });
            const ratingData = new FormData();
            ratingData.set('appPlatform', 'web');
            ratingData.set('combinedTitleID', this.ratingCombinedTitleID);
            ratingData.set('method', 'titleStatus');
            ratingData.set('status', status);

            fetch(GATEWAY_DOMAIN + '/userActions/', {
                method: 'POST',
                credentials: 'include',
                body: ratingData
            })
                .then((response) => {
                    return response.json();
                })
                .then(() => {
                    showToast('Status updated!', 'bottomCenter');
                });
        }
    }

    // As we scroll, look for carousels that weren't fetched, and fetch their posters
    unfetchedCarouselsIntersectionObserver() {
        this.observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting === true && entries[0].target.dataset.fetched == 0) this.fetchLazyLoadedCarousels(entries[0].target.dataset.carouselid);
        });
        document.querySelectorAll('.posterCarousel').forEach((element) => {
            this.observer.observe(element);
        });
    }

    // When we scroll to a non fetched carousel, fetch the data for it and the next 3 unfetched carousels
    fetchLazyLoadedCarousels(startingCarouselID) {
        const posterCarouselDivs = document.querySelectorAll('.posterCarousel');
        let foundCarousel = false;
        posterCarouselDivs.forEach((posterCarousel) => {
            if (posterCarousel.dataset.fetched == 0) {
                if (posterCarousel.dataset.carouselid == startingCarouselID) {
                    foundCarousel = true;
                }
                if (foundCarousel) {
                    if (this.carouselFetchQueue.length < 4) {
                        if (!this.carouselFetchQueue.includes(posterCarousel.dataset.carouselid.replace('carousel', ''))) {
                            this.carouselFetchQueue.push(posterCarousel.dataset.carouselid.replace('carousel', ''));
                            posterCarousel.dataset.fetched = 1;
                        }
                    }
                }
            }
        });

        const carouselData = new FormData();
        carouselData.set('appPlatform', 'web');
        carouselData.set('dashboardSessionId', this.dashboardSessionId);
        carouselData.set('carouselIDs', this.carouselFetchQueue.join(','));

        this.carouselFetchQueue = [];
        fetch(GATEWAY_DOMAIN + '/dashboardWebsiteCarousels/', {
            method: 'POST',
            credentials: 'include',
            body: carouselData
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                data.carousels.forEach((carousel) => {
                    posterCarouselDivs.forEach((posterCarouselDiv) => {
                        if (posterCarouselDiv.dataset.carouselid == carousel.id) {
                            if (carousel.tilesHTML.length == 0) {
                                posterCarouselDiv.parentElement.parentElement.remove();
                            } else {
                                posterCarouselDiv.parentElement.outerHTML = carousel.tilesHTML;
                            }
                        }
                    });
                });
            })
            .then(() => {
                // rebuild the intersection observer
                this.tileLazyLoadIntersectionObserver();
                // Reset the monitoring of the carousels to catch the ones we just added
                monitorCarouselDrags();
            });
    }

    tileLazyLoadIntersectionObserver() {
        const lazyloadImages = document.querySelectorAll('.lazy');
        lazyloadImages.forEach((image) => {
            if (!image.dataset.observed) {
                this.imageObserver.observe(image);
                image.dataset.observed = 1;
            }
        });
    }

    setListManagerButtonIcon(combinedTitleID) {
        const posterTiles = document.querySelectorAll('.pts');
        posterTiles.forEach((posterTile) => {
            if (posterTile.dataset.ctid == combinedTitleID) {
                const lmlIcon = posterTile.querySelector('.lmlIcon');
                if (posterTile.dataset.myshowsstatus == 1) {
                    lmlIcon.classList.add('mt-1');
                    lmlIcon.src = 'https://cdn.watchmode.com/icons/tv-white.svg';
                    lmlIcon.width = 24;
                    lmlIcon.height = 24;
                } else {
                    lmlIcon.classList.remove('mt-1');
                    lmlIcon.width = 34;
                    lmlIcon.height = 34;
                    if (posterTile.dataset.watchliststatus == 1 || posterTile.dataset.incustomlists != '[]') {
                        lmlIcon.src = 'https://cdn.watchmode.com/icons/check.svg';
                    } else {
                        lmlIcon.src = 'https://cdn.watchmode.com/icons/plus.svg';
                    }
                }
                const lmlCountBadge = posterTile.querySelector('.tileWatchlistCount');
                const inCustomLists = JSON.parse(posterTile.dataset.incustomlists);
                const watchlistCount = inCustomLists.length + parseInt(posterTile.dataset.watchliststatus);
                if (watchlistCount > 1) {
                    lmlCountBadge.innerHTML = watchlistCount;
                    lmlCountBadge.classList.add('d-block');
                    lmlCountBadge.classList.remove('d-none');
                } else {
                    lmlCountBadge.classList.add('d-none');
                    lmlCountBadge.classList.remove('d-block');
                }
                const lmlContainer = posterTile.querySelector('.tileWatchlistButton');
                if (posterTile.dataset.myshowsstatus == 1) {
                    lmlContainer.classList.remove('watchlistBgGreen', 'watchlistBgBlack');
                    lmlContainer.classList.add('watchlistBgOrange');
                } else {
                    lmlContainer.classList.remove('watchlistBgOrange');
                    if (posterTile.dataset.watchliststatus == 1 || posterTile.dataset.incustomlists != '[]') {
                        lmlContainer.classList.add('watchlistBgGreen');
                        lmlContainer.classList.remove('watchlistBgBlack');
                    } else {
                        lmlContainer.classList.add('watchlistBgBlack');
                        lmlContainer.classList.remove('watchlistBgGreen');
                    }
                }
            }
        });
    }

    reloadDashboard() {
        const spinner = document.querySelector('.spinner-wrapper');
        spinner.classList.add('fadeInFast');
        spinner.classList.remove('fadeOutFast');
        spinner.style.zIndex = '9999999';
        var parameters = {};
        var services = [];
        if (document.getElementById('dashboardServicesMy').classList.contains('btn-primary')) {
            services.push('my');
        }
        if (document.getElementById('dashboardServicesAll').classList.contains('btn-primary')) {
            services.push('all');
        }
        if (document.getElementById('dashboardServicesRent').classList.contains('btn-primary')) {
            services.push('rent');
        }
        if (document.getElementById('dashboardServicesBuy').classList.contains('btn-primary')) {
            services.push('buy');
        }
        if (document.getElementById('dashboardServicesFree').classList.contains('btn-primary')) {
            services.push('free');
        }
        if (document.getElementById('dashboardServicesDontHave').classList.contains('btn-primary')) {
            services.push('donthave');
        }
        parameters.hideKids = document.getElementById('dashboardHideKids').classList.contains('btn-primary') ? 1 : 0;
        parameters.hideMature = document.getElementById('dashboardHideMature').classList.contains('btn-primary') ? 1 : 0;
        parameters.hideNonEn = document.getElementById('dashboardHideNonEn').classList.contains('btn-primary') ? 1 : 0;
        parameters.hideSeen = document.getElementById('dashboardHideSeen').classList.contains('btn-primary') ? 1 : 0;
        parameters.hideRatedNegative = document.getElementById('dashboardHideRatedNegative').classList.contains('btn-primary') ? 1 : 0;
        parameters.hideAnimation = document.getElementById('dashboardHideAnimation').classList.contains('btn-primary') ? 1 : 0;
        parameters.services = services;

        window.location.href = '/dashboard/?parameters=' + encodeURIComponent(JSON.stringify(parameters));
    }

    handleDashboardCollectionsSave() {
        var collectionsShow = [];
        var collectionsHide = [];

        const collectionsOutputLink = document.getElementById('collectionsOutput');
        const button = document.getElementById('dashboardCollectionsSave');
        button.disabled = true;

        const collectionCheckboxes = document.querySelectorAll('.collectionToggle');
        collectionCheckboxes.forEach((checkbox) => {
            if (checkbox.checked) {
                collectionsShow.push(checkbox.dataset.carouselid);
            } else {
                collectionsHide.push(checkbox.dataset.carouselid);
            }
        });

        const data = new FormData();
        data.set('appPlatform', 'web');
        data.set('method', 'saveCarouselSettings');
        collectionsShow.forEach((carouselID) => {
            data.append('carouselsShow[]', carouselID);
        });
        collectionsHide.forEach((carouselID) => {
            data.append('carouselsHide[]', carouselID);
        });
        fetch(GATEWAY_DOMAIN + '/userActions/', {
            method: 'POST',
            credentials: 'include',
            body: data
        })
            .then((response) => {
                return response.json();
            })
            .then(() => {
                collectionsOutputLink.innerHTML = collectionsShow.length + ' shown, ' + collectionsHide.length + ' hidden';
                showToast('Settings saved! Reloading...', 'bottomCenter');
                this.reloadDashboard();
            });
    }
}
