import { WATCHMODE_CDN1 } from '../constants.js';

export const buildWatchButton = (title, selectedProviders, smallMode) => {
    let foundProvider = {};
    let foundRental = false;
    let rentalProvider = {};
    let rentalPrice = null;

    for (const provider of title.consolidatedProviders) {
        if (provider.type === 'sub' || provider.type === 'tve') {
            if (provider.userHasProvider) {
                if (!foundProvider.priority || provider.priority > foundProvider.priority || !foundProvider.userHasProvider) {
                    foundProvider = provider;
                }
            } else {
                if (!foundProvider.userHasProvider && (provider.priority > foundProvider.priority || (!foundProvider.userHasProvider && selectedProviders.includes(provider.providerID) && !selectedProviders.includes(foundProvider.providerID)))) {
                    foundProvider = provider;
                }
            }
        }

        if (!foundProvider.providerID && provider.type === 'free') {
            if (provider.userHasProvider) {
                if (!foundProvider.priority || provider.priority > foundProvider.priority || !foundProvider.userHasProvider) {
                    foundProvider = provider;
                }
            } else {
                if (!foundProvider.userHasProvider && (provider.priority > foundProvider.priority || (!foundProvider.userHasProvider && selectedProviders.includes(provider.providerID) && !selectedProviders.includes(foundProvider.providerID)))) {
                    foundProvider = provider;
                }
            }
        }

        if (!foundProvider.providerID && (title.type === 'movie' || title.type === 'tv_movie')) {
            if (provider.type === 'rent') {
                foundRental = true;
                if (rentalPrice === null || provider.price < rentalPrice) {
                    rentalPrice = provider.price;
                    rentalProvider = provider;
                }
            }
        }
    }

    // If no provider found, choose the highest priority provider
    if (!foundProvider.providerID) {
        for (const provider of title.consolidatedProviders) {
            if (!foundProvider.priority || provider.priority > foundProvider.priority) {
                foundProvider = provider;
            }
        }
    }

    let link = '';
    let buttonText = '';
    let buttonStyles = '';
    let playIcon = foundProvider.backgroundColor === '#FFFFFF' ? 'play-fill.svg' : 'play-fill-white.svg';
    let watchLabel = foundProvider.type === 'free' ? 'Free' : 'Watch';

    if (foundProvider.providerID) {
        if (foundProvider.backgroundColor) {
            buttonStyles += `background-color: ${foundProvider.backgroundColor} !important;`;
            buttonStyles += `border-color: ${foundProvider.backgroundColor} !important;`;
        }
        if (foundProvider.textColor) {
            buttonStyles += `color: ${foundProvider.textColor} !important;`;
        }
        link = foundProvider.webLink;
        buttonText = smallMode ? foundProvider.name.replace(' (Via Amazon Prime)', '') : `${watchLabel} on ${foundProvider.name}`;
    } else if (foundRental) {
        link = rentalProvider.webLink;
        playIcon = 'play-fill-white.svg';
        buttonText = `Rent From $${rentalPrice}`;
    }

    const buttonSize = smallMode ? 18 : 23;
    const buttonSizeClasses = smallMode ? 'watchButtonSmall' : 'btn watchButton';
    const button = `<a href="${link}" rel="noopener" target="_blank" class="pb-1 ${buttonSizeClasses}" style="${buttonStyles}">
                    <img src="${WATCHMODE_CDN1}/icons/${playIcon}" style="${buttonStyles}" alt="Watch" width="${buttonSize}" height="${buttonSize}" class="me-1"> ${buttonText}
                    </a>`;
    return button;
};
