export const buildRatingOutput = (rating, ratingType = 1) => {
    if (!rating) return '';
    let classScore = '';
    if (ratingType === 1 && rating !== null) {
        classScore = rating >= 7.2 ? 'content-score-green' : '';
        classScore = rating < 7.2 && rating >= 6.2 ? 'content-score-yellow' : classScore;
        classScore = rating < 6.2 ? 'content-score-red' : classScore;
    } else if (ratingType === 2 && rating !== null) {
        classScore = rating >= 80 ? 'content-score-green' : '';
        classScore = rating < 80 && rating >= 70 ? 'content-score-yellow' : classScore;
        classScore = rating < 70 ? 'content-score-red' : classScore;
    }

    const ratingTypeOutput = ratingType === 1 ? 'User Score' : 'Critic Score';

    const html = `<div class="ratingsBox"><div class="${classScore} content-score-dash content-score-dash" style="margin-right:5px;">${rating}</div></div>`;
    const wrapper = `<span class="textSmall">${ratingTypeOutput}:</span>&nbsp;${html}&nbsp;`;
    return wrapper;
};
